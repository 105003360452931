import React, { useState, useEffect } from "react";
import EventSlotInput from "../event-slot-input/event-slot-input";
import "./event-editor-popup.scss";

import { store } from "../../../redux/store";
import { showLoader, hideLoader } from "../../../redux/actions/loader-data";
import ManageEventHelper from "../../helpers/manage-events-helper";

const formatDateForInput = (dateString) => {
  let [day, month, year] = dateString.split("/");
  day = day?.padStart(2, "0");
  month = month?.padStart(2, "0");
  return `${year}-${month}-${day}`; // Converts to 'yyyy-mm-dd'
};

const formatDateForBackend = (dateString) => {
  const [year, month, day] = dateString.split("-");
  return `${day?.padStart(2, "0")}/${month?.padStart(2, "0")}/${year}`; // Converts back to 'dd/mm/yyyy'
};

const focusOptions = [
  { focus_id: "14cd8fc76bbf5e51a31cf1b854c29dc8", focus_name: "Rotorcraft" },
  { focus_id: "5adbdeef2e506436356338c5512bd477", focus_name: "Piston" },
  { focus_id: "d41d8cd98f00b204e9800998ecf8427e", focus_name: "Turbine" },
  {
    focus_id: "tt41d8cd98f00b204e9800998ecf842ww",
    focus_name: "South Carolina",
  },
];

const EventEditorPopup = ({
  isEditing = false,
  event = null,
  onSubmit = () => {},
  onCancel = () => {},
}) => {
  const [eventData, setEventData] = useState({
    event_name: event?.event_name || "",
    slots: event?.slots || [],
    start_date: event?.slots?.[0]?.start_date
      ? formatDateForInput(event.slots[0].start_date)
      : "", // Convert to 'yyyy-mm-dd'
    end_date: event?.slots?.[event?.slots?.length - 1]?.end_date
      ? formatDateForInput(event.slots[event.slots.length - 1].end_date)
      : "", // Convert to 'yyyy-mm-dd'
  });
  const [slotActionsQueue, setSlotActionsQueue] = useState([]); // To track add/edit/delete slots
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedFocus, setSelectedFocus] = useState(
    event?.slots?.[0]?.focus_id || ""
  ); // Default focus_id from slots

  // DELETE event logic
  const handleDeleteEvent = async () => {
    if (!window.confirm("Are you sure you want to delete this event?")) {
      return; // Cancel if user doesn't confirm
    }

    store.dispatch(showLoader("Deleting event..."));

    try {
      await ManageEventHelper.deleteEvent(event); // Call the delete function
      onSubmit(); // Refresh the event list
      onCancel(); // Close the modal after successful deletion
    } catch (error) {
      console.error("Error deleting event:", error);
      setErrorMessage("Failed to delete the event. Please try again.");
    }

    store.dispatch(hideLoader("Deleting event..."));
  };

  const handleEventNameChange = (e) => {
    setEventData({
      ...eventData,
      event_name: e.target.value,
    });
  };

  const handleFocusChange = (e) => {
    const selectedFocusId = e.target.value;
    setSelectedFocus(selectedFocusId);

    const updatedSlots = eventData.slots.map((slot) => ({
      ...slot,
      focus_id: selectedFocusId, // Attach the focus_id to each slot
    }));

    setEventData({
      ...eventData,
      slots: updatedSlots,
    });
    console.log("updatedSlots :>> ", updatedSlots);
    // Queue the changes (merge if already present)
    updatedSlots.forEach((slot) => updateSlotQueue("edit", slot));
  };

  const updateSlotQueue = (actionType, updatedSlot) => {
    setSlotActionsQueue((prevQueue) => {
      // For slot edits, ensure only the latest edit is applied (no duplicates)
      if (actionType === "edit") {
        // Check if the slot is already in the queue
        const existingIndex = prevQueue.findIndex(
          (action) => action.payload.event_slot_id === updatedSlot.event_slot_id
        );
        if (existingIndex !== -1) {
          // Replace the existing action with the updated slot
          const newQueue = [...prevQueue];
          newQueue[existingIndex] = {
            actionType: "edit",
            payload: updatedSlot,
          };
          return newQueue;
        }
      }

      // For new slots that are deleted before submission, don't queue them
      if (actionType === "delete" && !updatedSlot.event_slot_id) {
        // Don't queue newly added but unsaved slots for deletion
        return prevQueue.filter(
          (action) => action.payload.event_slot_id !== updatedSlot.event_slot_id
        );
      }

      // For other cases (add, delete, etc.), queue the action as normal
      return [...prevQueue, { actionType, payload: updatedSlot }];
    });
  };

  const handleStartDateChange = (e) => {
    const newStartDate = formatDateForBackend(e.target.value);
    const updatedSlots = eventData.slots.map((slot) => ({
      ...slot,
      start_date: newStartDate,
    }));

    setEventData({
      ...eventData,
      start_date: e.target.value,
      slots: updatedSlots,
    });

    // Queue the changes (merge if already present)
    updatedSlots.forEach((slot) => updateSlotQueue("edit", slot));
  };

  const handleEndDateChange = (e) => {
    const newEndDate = formatDateForBackend(e.target.value);
    const updatedSlots = eventData.slots.map((slot) => ({
      ...slot,
      end_date: newEndDate,
    }));

    setEventData({
      ...eventData,
      end_date: e.target.value,
      slots: updatedSlots,
    });

    // Queue the changes (merge if already present)
    updatedSlots.forEach((slot) => updateSlotQueue("edit", slot));
  };

  const handleSlotAdd = (slot) => {
    const newSlot = {
      ...slot,
      event_id: event?.event_id || null, // Ensure the event_id is passed in the payload
      start_date: formatDateForBackend(eventData.start_date),
      end_date: formatDateForBackend(eventData.end_date),
      focus_id: selectedFocus,
    };

    setEventData({
      ...eventData,
      slots: [...eventData.slots, newSlot],
    });

    // Queue the new slot for addition
    updateSlotQueue("add", newSlot);
  };

  const handleSlotRemove = (index, existingSlotId) => {
    const updatedSlots = [...eventData.slots];
    const removedSlot = updatedSlots.splice(index, 1)[0]; // Get the removed slot
    setEventData({
      ...eventData,
      slots: updatedSlots,
    });

    // Queue the deletion if the slot already exists in the database (i.e., has an event_slot_id)
    if (existingSlotId) {
      updateSlotQueue("delete", removedSlot);
    }
  };

  const handleSlotUpdate = (updatedSlot, index) => {
    const updatedSlots = [...eventData.slots];
    updatedSlots[index] = updatedSlot;
    setEventData({
      ...eventData,
      slots: updatedSlots,
    });

    setSlotActionsQueue((prevQueue) => [
      ...prevQueue,
      { actionType: "edit", payload: updatedSlot },
    ]);
  };

  const convertTimeToMinutes = (timeStr) => {
    const [hours, minutes] = timeStr.split(":").map(Number);
    return hours * 60 + minutes; // Convert time to total minutes
  };

  const validateEvent = () => {
    setErrorMessage("");
    if (!eventData.event_name.trim()) {
      setErrorMessage("Event name is required.");
      return false;
    }

    if (eventData.slots.length === 0) {
      setErrorMessage("At least one slot is required.");
      return false;
    }

    // Sort the slots by start_time before checking for overlaps
    const sortedSlots = [...eventData.slots].sort((a, b) => {
      return (
        convertTimeToMinutes(a.start_time) - convertTimeToMinutes(b.start_time)
      );
    });

    // Ensure no slots overlap by converting time strings to minutes and comparing
    for (let i = 0; i < sortedSlots.length - 1; i++) {
      const currentSlotEnd = convertTimeToMinutes(sortedSlots[i].end_time);
      const nextSlotStart = convertTimeToMinutes(sortedSlots[i + 1].start_time);

      if (currentSlotEnd > nextSlotStart) {
        setErrorMessage("Slots should not overlap.");
        return false;
      }
    }

    return true;
  };

  const handleSubmit = async () => {
    if (!validateEvent()) return;

    const updatedEventData = {
      event_id: event?.event_id || null,
      event_name: eventData.event_name,
      speakers: event?.speakers || null,
      description: event?.description || null,
      isEnabled: event?.isEnabled ?? 1,
      featured_sponsor_id: event?.featured_sponsor_id || null,
      videoLink: event?.videoLink || null,
      event_status: "ACTIVE",
    };

    store.dispatch(showLoader("Saving event..."));

    try {
      let newEventId = event?.event_id; // Existing event ID

      // Create or update the event
      if (isEditing) {
        await ManageEventHelper.updateEvent(updatedEventData);
      } else {
        const response = await ManageEventHelper.addEvent(updatedEventData);
        console.log("response :>> ", response);
        newEventId = response?.data?.event_id; // Retrieve event_id from the response
      }

      // Update the queued slot actions with the correct event_id
      const updatedSlotActionsQueue = slotActionsQueue.map((action) => ({
        ...action,
        payload: {
          ...action.payload,
          event_id: newEventId, // Ensure the event_id is passed for each slot
        },
      }));

      console.log("updatedSlotActionsQueue :>> ", updatedSlotActionsQueue);

      // Process the slot queue for add/edit/delete actions
      for (const action of updatedSlotActionsQueue) {
        if (action.actionType === "add") {
          await ManageEventHelper.addEventSlot(action.payload);
        } else if (action.actionType === "edit") {
          await ManageEventHelper.updateEventSlot(action.payload);
        } else if (action.actionType === "delete") {
          await ManageEventHelper.deleteEventSlot(action.payload);
        }
      }

      onSubmit(); // Callback to refresh the events list
    } catch (error) {
      console.error("Error saving event:", error);
    }
    store.dispatch(hideLoader("Saving event..."));
  };

  return (
    <>
      <div className="addEditEventModal show">
        <div className="overlay" onClick={onCancel}></div>

        <div className="modalContainer">
          <div className="modalHeader">
            <h3 className="mb-0">
              <b>{isEditing ? "Edit Event" : "Add Event"}</b>
            </h3>
            <span className="close" onClick={onCancel}>
              <i className="fa fa-times"></i>
            </span>
          </div>

          <div className="modalContent">
            {errorMessage && (
              <div className="error-message">{errorMessage}</div>
            )}

            <div className="row">
              <div className="col-md-12">
                <div className="form-group mb-2">
                  <label className="mb-1">Event Name</label>
                  <input
                    type="text"
                    className="form-control"
                    value={eventData.event_name}
                    onChange={handleEventNameChange}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group mb-2">
                  <label className="mb-1">Start Date</label>
                  <input
                    type="date"
                    className="form-control"
                    value={eventData.start_date}
                    onChange={handleStartDateChange}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group mb-2">
                  <label className="mb-1">End Date</label>
                  <input
                    type="date"
                    className="form-control"
                    value={eventData.end_date}
                    onChange={handleEndDateChange}
                  />
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group mb-2">
                  <label className="mb-1">Select Event Type</label>
                  <select
                    className="form-control active"
                    value={selectedFocus}
                    onChange={handleFocusChange}
                  >
                    <option value="" disabled>
                      Select Event Type
                    </option>
                    {focusOptions.map((focus) => (
                      <option key={focus.focus_id} value={focus.focus_id}>
                        {focus.focus_name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="col-md-12 mt-3 py-3 border-top border-bottom">
                <div className="fw-bold mb-2">Slots</div>

                <EventSlotInput onAddSlot={handleSlotAdd} />

                <div className="slots">
                  {eventData.slots.map((slot, index) => (
                    <div className="slot" key={index}>
                      <span>
                        {slot.start_time} - {slot.end_time}
                      </span>{" "}
                      <i
                        className="fa fa-times"
                        onClick={() => handleSlotRemove(index, slot)}
                      ></i>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="modalFooter text-end mt-4">
            {/* Add delete button only when editing */}
            {isEditing && (
              <button
                className="btn btn-danger me-2"
                onClick={handleDeleteEvent}
              >
                Delete Event
              </button>
            )}
            <button className="btn btn-primary" onClick={handleSubmit}>
              {isEditing ? "Update" : "Add"} Event
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default EventEditorPopup;
