import React, { Component } from "react";
import Modal from "react-awesome-modal";
import "./event-details-prompt.scss";
import moment from "moment";
import { connect } from "react-redux";
import { hideLoader, showLoader } from "../../redux/actions/loader-data";
import { eventRegistration } from "../../http-calls";
import { convert24To12, showToast } from "../../helper-methods";

class EventDetailsPrompt extends Component {
  state = {
    selectedFocus: null,
    selectedSlot: null,
  };

  _canSubmit = () => {
    return this.state.selectedFocus && this.state.selectedSlot;
  };

  _getFocusClass = (focus) => {
    const { selectedFocus } = this.state;
    if (selectedFocus && selectedFocus.focus_id === focus.focus_id) {
      return "selector selected";
    } else {
      return "selector";
    }
  };

  _dismiss = () => {
    this._resetState();
    this.props.onDimiss();
  };

  _resetState = () => {
    this.setState({
      selectedFocus: null,
      selectedSlot: null,
    });
  };

  render() {
    const { isVisible, event, onRegister } = this.props;

    return (
      <>
        <Modal
          visible={isVisible}
          width="800"
          height="650"
          effect="fadeInUp"
          onClickAway={this._dismiss}
        >
          {event ? (
            <div className="detailsPromptWRapper">
              <div className="eventTitle">
                <div className="eventName">{event.eventName}</div>
                <div className="date">
                  {moment(event.startDate, "DD/MM/YYYY").format("DD MMM YYYY")}{" "}
                  - {moment(event.endDate, "DD/MM/YYYY").format("DD MMM YYYY")}
                </div>
                <div className="timeSLots">
                  {
                    event.slots.map((slot) => (
                      <div className="slot">{convert24To12(slot.startTime)} - {convert24To12(slot.endTime)}</div>
                    ))
                  }
                </div>
              </div>
              <div className="eventDetailsSelector">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-6 grid-margin grid-margin-md-0 stretch-card">
                      <h4 className="card-title">Virtual IA Renewal series</h4>
                      <ul className="list-star">
                        <li>FFA Approived IA Refresher training event</li>
                        <li>Free event</li>
                        <li>All mentioned days are available</li>
                        <li>Online Space is limited</li>
                      </ul>
                    </div>
                    <div className="col-lg-6">
                      <img
                        src="https://www.wallpapertip.com/wmimgs/74-745706_corporate-events-wallpaper-hd.jpg"
                        alt=""
                        className="eventCover"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-4 speakersWrapper">
                      <div className="heading">Day 1 Speakers</div>
                      <div className="speakers">
                        <div className="speaker">
                          <i class="mdi mdi-airplane"></i> Rapco INC
                        </div>
                        <div className="speaker">
                          <i class="mdi mdi-airplane"></i> Goodinch De-Ice
                        </div>
                        <div className="speaker">
                          <i class="mdi mdi-airplane"></i> Champion Aerospace
                        </div>
                      </div>
                    </div>
                    <div className="col-4 speakersWrapper">
                      <div className="heading">Day 2 Speakers</div>
                      <div className="speakers">
                        <div className="speaker">
                          <i class="mdi mdi-airplane"></i> Tempest Aero Group
                        </div>
                        <div className="speaker">
                          <i class="mdi mdi-airplane"></i> G&G Engines
                        </div>
                        <div className="speaker">
                          <i class="mdi mdi-airplane"></i> Hartzell Propeller
                        </div>
                        <div className="speaker">
                          <i class="mdi mdi-airplane"></i> Lycoming
                        </div>
                      </div>
                    </div>
                    <div className="col-4 speakersWrapper">
                      <div className="heading">Day 3 Speakers</div>
                      <div className="speakers">
                        <div className="speaker">
                          <i class="mdi mdi-airplane"></i> Eagle Fuel Cells
                        </div>
                        <div className="speaker">
                          <i class="mdi mdi-airplane"></i> Goodyear
                        </div>
                        <div className="speaker">
                          <i class="mdi mdi-airplane"></i> ACF-50
                        </div>
                        <div className="speaker">
                          <i class="mdi mdi-airplane"></i> Concorde-Battery
                          Corporation
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="eventActions">
                <div className="cancel" onClick={this._dismiss}>
                  Dismiss
                </div>
                <div className="register" onClick={onRegister}>
                  Register
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </Modal>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (text) => dispatch(showLoader(text)),
    hideLoader: () => dispatch(hideLoader()),
  };
};

export default connect(null, mapDispatchToProps)(EventDetailsPrompt);
