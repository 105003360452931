import React, { useState } from "react";
import useDeepCompareEffect from "use-deep-compare-effect";
import "./speakers-video-grid.scss";

const SpeakersVideoGrid = ({ allSpeakers }) => {
  const [videoLinks, setVideoLinks] = useState([]);

  useDeepCompareEffect(() => {
    console.log("allSpeakers :>> ", allSpeakers);
    const [day1Speakers, day2Speakers] = allSpeakers;
    let combinedSpeakers = [];
    if (day1Speakers?.length) {
      combinedSpeakers = [...day1Speakers];
    }
    if (day2Speakers?.length) {
      combinedSpeakers = [...combinedSpeakers, ...day2Speakers];
    }
    if (combinedSpeakers?.length) {
      const videoLinks = [];
      combinedSpeakers.forEach((speaker) => {
        console.log("speaker.youtube_video_link 2222321:>> ", speaker);
        let youtubeLink = "";
        if (speaker.youtube_video_link?.length) {
          const link = speaker?.youtube_video_link;
          if (link.indexOf("youtube.com") > -1) {
            const linkParts = link.split("watch?v=");
            if (linkParts?.length === 2) {
              youtubeLink = `https://www.youtube.com/embed/${linkParts[1]}`;
            }
          }
        }
        if (youtubeLink?.length) {
          videoLinks.push(youtubeLink);
        }
      });
      console.log("videoLinks 2222321:>> ", videoLinks);
      setVideoLinks(videoLinks);
    }
  }, [allSpeakers]);

  return (
    <>
      {/* Need a bootstrap accordian */}
      {videoLinks?.length ? (
        <>
          <div className="accordion" id="accordionExample">
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingOne">
                <button
                  className="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  Speaker videos
                </button>
              </h2>
              <div
                id="collapseOne"
                className="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <div className="speakersVideoGrid">
                    <div className="container-fluid">
                      <div className="row">
                        {videoLinks?.map((videoLink) => {
                          return (
                            <div className="col-6 mb-6 mt-6">
                              <div className="videoCard">
                                <iframe
                                  width="100%"
                                  height="315"
                                  src={videoLink}
                                  title="YouTube video player"
                                  frameborder="0"
                                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                ></iframe>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default SpeakersVideoGrid;
