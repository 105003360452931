/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import FirebaseAuthService from "../../../firebase-auth-service";
import { showToast } from "../../../helper-methods";
// import { login } from "../../../http-calls";
import { hideLoader, showLoader } from "../../../redux/actions/loader-data";
import { updateUserData } from "../../../redux/actions/user-data";

class AdminForgotPasswordPage extends Component {
  state = {
    formFields: {
      email: {
        value: "",
        isValid: false,
        isDirty: false,
      },
      password: {
        value: "",
        isValid: false,
        isDirty: false,
      },
    },
    isFormValid: false,
    redirectTo: null,
  };

  componentDidMount() {
    this._storeRedirectParamsIfAvailable();
    if (this.props.userData.token) {
      // Already logged in
      this.props.history.replace("/");
    }
    this.props.showLoader("Loading");
    setTimeout(() => {
      this.props.hideLoader();
    }, 1000);
  }

  _storeRedirectParamsIfAvailable = () => {
    const { location } = this.props;
    if (location.extras) {
      this.setState(
        {
          redirectTo: location.extras.pathname + location.extras.search,
        },
        () => {
          if (
            location.extras.pathname !== "" &&
            location.extras.pathname !== "/"
          ) {
            showToast("Please login first to continue");
          }
        }
      );
    }
  };

  _markAsDirty = (fieldName) => {
    const { formFields } = this.state;
    formFields[fieldName].isDirty = true;
    this.setState({ formFields });
    this._validateForm();
  };

  _updateFieldValue = (fieldName, value) => {
    const { formFields } = this.state;
    formFields[fieldName].value = value;
    this.setState({ formFields });
    if (formFields[fieldName].isDirty) {
      // Validate
      this._validateForm();
    }
  };

  _validateForm = () => {
    const { formFields } = this.state;
    let isFormValid = true;
    Object.keys(formFields).forEach((fieldName, index) => {
      switch (fieldName) {
        case "email": {
          var emailRegex =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          if (emailRegex.test(String(formFields.email.value).toLowerCase())) {
            formFields.email.isValid = true;
          } else {
            formFields.email.isValid = false;
            isFormValid = false;
          }
          break;
        }
      }
    });
    this.setState({ formFields, isFormValid });
  };

  _makeAllFieldDirty = () => {
    const { formFields } = this.state;
    Object.keys(formFields).forEach((fieldName, index) => {
      formFields[fieldName].isDirty = true;
    });
  };

  _validateAndSubmit = async (e) => {
    e.preventDefault();
    this._makeAllFieldDirty();
    this._validateForm();
    const { formFields, isFormValid } = this.state;
    if (isFormValid) {
      try {
        this.props.showLoader("Please wait");
        await FirebaseAuthService.sendResetPasswordEmail(
          formFields.email.value
        );
        showToast(
          "Reset link sent to your email",
          "success"
        );
        this.props.history.push("login");
      } catch (loginError) {
        this.props.hideLoader();
        showToast(
          loginError.reason ? loginError.reason : "Login server error",
          "error"
        );
      }
    }
  };

  render() {
    const { formFields } = this.state;
    return (
      <React.Fragment>
        <div className="container-scroller">
          <div className="container-fluid page-body-wrapper full-page-wrapper login-page-wrapper">
            <div className="content-wrapper d-flex align-items-center auth px-0">
              <div className="row w-100 mx-0">
                <div className="col-lg-4 mx-auto">
                  <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                    <div className="brand-logo">
                      <img
                        src={require("../../../assets/images/concorde-logo.png")}
                        alt="logo"
                      />
                    </div>
                    <h4>Forgot Password</h4>
                    <h6 className="fw-light">
                      Get a reset a link on your registered email id
                    </h6>
                    <form className="pt-3" onSubmit={(e) => this._validateAndSubmit(e)}>
                      <div className="form-group">
                        <input
                          type="email"
                          className="form-control form-control-lg"
                          id="exampleInputEmail1"
                          placeholder="Email"
                          value={formFields.email.value}
                          onChange={(e) =>
                            this._updateFieldValue("email", e.target.value)
                          }
                          onBlur={() => this._markAsDirty("email")}
                        />
                        <div className="field-error-wrapper">
                          {formFields.email.isDirty && !formFields.email.isValid
                            ? "Invalid Email"
                            : null}
                        </div>
                      </div>
                      <div className="mt-3">
                        <button
                          className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                          type="submit"
                        >
                          SEND RESET LINK
                        </button>
                      </div>
                      <div className="text-center mt-4 fw-light pt-4">
                        <a href="#" className="text-primary"  onClick={(e) => {
                            e.preventDefault();
                            this.props.history.push("/admin/login");
                          }}>
                          BACK TO LOGIN
                        </a>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* content-wrapper ends */}
          </div>
          {/* page-body-wrapper ends */}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (text) => dispatch(showLoader(text)),
    hideLoader: () => dispatch(hideLoader()),
    updateUserData: (userData) => dispatch(updateUserData(userData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminForgotPasswordPage);
