import React from "react";
import Modal from "react-awesome-modal";
// import "./speaker-details-modal.scss";
import { EventEmitter } from "../../utils/event-emitter";
import LinkActions from "../link-actions/link-actions";

class SponsorDetailsModal extends React.Component {
  state = {
    isVisible: false,
    speaker: null,
  };

  componentDidMount() {
    this._listenToEvents();
  }

  _listenToEvents = () => {
    EventEmitter.subscribe("show-sponsor-details", (speaker) => {
      this.setState({ isVisible: true, speaker });
    });
  };

  _renderCompanyDescription = (description) => {
    if (typeof description === "string") {
      return <>{description}</>;
    } else {
      return (
        <>
          {description.map((d, dindex) => (
            <>
              <div className="descriptionHeader" key={dindex}>
                {d.title}
              </div>
              <ul>
                {d.points.map((p, pIndex) => (
                  <React.Fragment key={pIndex}>
                    {typeof p === "string" && p.length ? (
                      <li>{p}</li>
                    ) : (
                      <>
                        {p?.label?.length ? (
                          <>
                            <li>{p.label}</li>
                            {p.subLabels?.length ? (
                              <>
                                <ul>
                                  {p.subLabels.map(
                                    (subLabel, subLabelIndex) => (
                                      <li key={subLabelIndex}>{subLabel}</li>
                                    )
                                  )}
                                </ul>
                              </>
                            ) : (
                              <></>
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    )}
                  </React.Fragment>
                ))}
              </ul>
            </>
          ))}
        </>
      );
    }
  };

  _renderSpeakerDescription = (speakerDescription) => {
    if (typeof speakerDescription === "string") {
      return <>{speakerDescription}</>;
    } else {
      return (
        <ul style={{ textAlign: "left" }}>
          {speakerDescription.map((d, dindex) => (
            <li key={dindex}>{d}</li>
          ))}
        </ul>
      );
    }
  };

  _dismiss = () => {
    this.setState({ isVisible: false, speaker: null });
  };

  render() {
    const { speaker, isVisible } = this.state;

    return (
      <div className="speakerDetailsModalWrapper">
        <Modal
          visible={isVisible}
          width="1000"
          height="70%"
          effect="fadeInUp"
          onClickAway={this._dismiss}
        >
          {isVisible ? (
            <>
              <div className="detailsWrapper">
                <div className="speakerDetails">
                  <div className="companyWrapper">
                    <div className="logoPart">
                      <img src={speaker?.logo} alt="" />
                    </div>
                    <div className="companyData">
                      <div className="title">{speaker?.name}</div>
                      <div
                        className="description"
                        dangerouslySetInnerHTML={{
                          __html: speaker.description,
                        }}
                      ></div>
                    </div>
                  </div>
                  <div className="speakerWrapper">
                    <div className="contact">
                      <LinkActions
                        link={speaker?.contact_website}
                        linkType="webAddress"
                      />
                      <LinkActions
                        link={speaker?.contact_email}
                        linkType="email"
                      />
                    </div>
                  </div>
                  <div className="bottomDismissBar" onClick={this._dismiss}>
                    Dismiss
                  </div>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </Modal>
      </div>
    );
  }
}

export default SponsorDetailsModal;
