import React from "react";

const FeaturedSponsorSelector = ({ sponsor, event, onUpdate }) => {
  const _determineIfFeatured = () => {
    return event?.featured_sponsor_id === sponsor?.id;
  };

  const isFeatured = _determineIfFeatured();

  const _updateFeaturedOption = () => {
    if (isFeatured) {
      onUpdate({
        sponsor_id: "",
        event_id: event.event_id,
      });
    } else {
      onUpdate({
        sponsor_id: sponsor.id,
        event_id: event.event_id,
      });
    }
  };

  return (
    <div className={`featuredSponsorWrapper ${isFeatured && "featured"}`} onClick={e => e.stopPropagation()}>
      <label>
        <input
          type="checkbox"
          checked={isFeatured}
          onChange={_updateFeaturedOption}
        />
        <span>Featured sponsor</span>
      </label>
    </div>
  );
};

export default FeaturedSponsorSelector;
