/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import { logout } from "../../../helper-methods";
import LibraryScripts from "../../../library-scripts";
import { hideLoader, showLoader } from "../../../redux/actions/loader-data";
import RegistrationsDisplayPage from "../../pages/registrations-display-page/registrations-display-page";
import ProtectedRoute from "../../../components/protected-routes";
import { Switch } from "react-router-dom";
import { Route } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { clearUserData } from "../../../redux/actions/user-data";
import ManageEventSponsorsPage from "../../pages/manage-event-sponsors-page/manage-event-sponsors-page";
import ManageEventSpeakersPage from "../../pages/manage-event-speakers-page/manage-event-speakers-page";
import NonRegisteredUsersPage from "../../pages/non-registered-users-page/non-registered-users-page";
import ManageEventsPage from "../../pages/manage-events-page/manage-events-page";

class AdminDashboardLayout extends Component {
  state = {};

  componentDidMount() {
    this._checkIfAdmin();
    this._loadLibraryScripts();
  }

  _checkIfAdmin = () => {
    if (!this.props.userData.isAdmin) {
      this.props.clearUserData();
      this.props.history.replace("/admin");
    }
  };

  _loadLibraryScripts = () => {
    LibraryScripts.runLibraryScripts();
  };

  getCurrentGreet = () => {
    const today = new Date();
    const curHr = today.getHours();
    if (curHr > 4 && curHr < 12) {
      return "Good Morning";
    } else if (curHr >= 12 && curHr < 18) {
      return "Good Afternoon";
    } else if (curHr >= 18 && curHr < 20) {
      return "Good Evening";
    } else {
      return "Hello";
    }
  };

  _getSidebarClass = (path) => {
    if (window.location.pathname.indexOf(path) > -1) {
      return "nav-item active";
    } else {
      return "nav-item";
    }
  };

  _getCollapseClass = (path) => {
    if (window.location.pathname.indexOf(path) > -1) {
      return "collapse show";
    } else {
      return "collapse";
    }
  };

  _loadEvent = (e, index) => {
    e.preventDefault();
    this.props.history.push(`/private/upcoming-events?event=${index}`);
  };

  _navigateTo = (path) => {
    this.props.history.push(path);
  };

  render() {
    const { userData } = this.props;

    return (
      <React.Fragment>
        <div className="container-scroller">
          {/* partial:partials/_navbar.html */}
          <nav className="navbar default-layout col-lg-12 col-12 p-0 fixed-top d-flex align-items-top flex-row">
            <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-start">
              <div className="me-3" id="toggleBtn">
                <button
                  className="navbar-toggler navbar-toggler align-self-center"
                  type="button"
                  data-bs-toggle="minimize"
                >
                  <span className="icon-menu" />
                </button>
              </div>
              <div>
                <a className="navbar-brand brand-logo" href="index.html">
                  <img
                    src={require("../../../assets/images/concorde-logo.png")}
                    alt="logo"
                  />
                </a>
                <a className="navbar-brand brand-logo-mini" href="#"></a>
              </div>
            </div>
            <div className="navbar-menu-wrapper d-flex align-items-top">
              <ul className="navbar-nav">
                <li className="nav-item font-weight-semibold d-none d-lg-block ms-0">
                  <h1 className="welcome-text admin-welcome">Admin Panel</h1>
                </li>
              </ul>
              <ul className="navbar-nav ms-auto">
                <li className="nav-item dropdown d-none d-lg-block user-dropdown">
                  <a
                    className="nav-link"
                    id="UserDropdown"
                    href="#"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      className="img-xs rounded-circle"
                      src="https://image.flaticon.com/icons/png/512/149/149071.png"
                      alt="Profile image"
                    />{" "}
                  </a>
                  <div
                    className="dropdown-menu dropdown-menu-right navbar-dropdown"
                    aria-labelledby="UserDropdown"
                  >
                    <div className="dropdown-header text-center">
                      <img
                        className="img-md rounded-circle profile-image"
                        src="https://image.flaticon.com/icons/png/512/149/149071.png"
                        alt="Profile image"
                      />
                      <p className="mb-1 mt-3 font-weight-semibold">
                        {userData?.first_name} {userData?.last_name}
                      </p>
                      <p className="fw-light text-muted mb-0">
                        {userData?.email}
                      </p>
                    </div>
                    <a
                      className="dropdown-item"
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        if (window.confirm("Are you sure want to logout?")) {
                          this.props.clearUserData();
                          this.props.history.replace("/admin");
                        }
                      }}
                    >
                      <i className="dropdown-item-icon mdi mdi-power text-primary me-2" />
                      Sign Out
                    </a>
                  </div>
                </li>
              </ul>
              <button
                className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
                type="button"
                data-bs-toggle="offcanvas"
              >
                <span className="mdi mdi-menu" />
              </button>
            </div>
          </nav>
          {/* partial */}
          <div className="container-fluid page-body-wrapper">
            {/* partial:partials/_settings-panel.html */}
            {/* partial */}
            {/* partial:partials/_sidebar.html */}
            <nav className="sidebar sidebar-offcanvas" id="sidebar">
              <ul className="nav">
                <li className="nav-item nav-category">Registrations</li>
                <li
                  className={this._getSidebarClass("/admin/private")}
                  onClick={(e) =>
                    this._navigateTo("/admin/private/all-registrations")
                  }
                >
                  <a className="nav-link" href="#">
                    <i className="menu-icon mdi mdi-table" />
                    <span className="menu-title">All Registrations</span>
                  </a>
                </li>
                <li
                  className={this._getSidebarClass("/admin/private")}
                  onClick={(e) =>
                    this._navigateTo("/admin/private/non-registered-users")
                  }
                >
                  <a className="nav-link" href="#">
                    <i className="menu-icon mdi mdi-table" />
                    <span className="menu-title">Non registered Users</span>
                  </a>
                </li>
              </ul>
              <ul className="nav">
                <li className="nav-item nav-category">Events</li>
                <li
                  className={this._getSidebarClass("/admin/private")}
                  onClick={(e) =>
                    this._navigateTo("/admin/private/manage-events")
                  }
                >
                  <a className="nav-link" href="#">
                    <i className="menu-icon mdi mdi-table" />
                    <span className="menu-title">Manage Events</span>
                  </a>
                </li>
                <li
                  className={this._getSidebarClass("/admin/private")}
                  onClick={(e) =>
                    this._navigateTo("/admin/private/manage-sponsors")
                  }
                >
                  <a className="nav-link" href="#">
                    <i className="menu-icon mdi mdi-table" />
                    <span className="menu-title">Manage Sponsors</span>
                  </a>
                </li>
                <li
                  className={this._getSidebarClass("/admin/private")}
                  onClick={(e) =>
                    this._navigateTo("/admin/private/manage-speakers")
                  }
                >
                  <a className="nav-link" href="#">
                    <i className="menu-icon mdi mdi-table" />
                    <span className="menu-title">Manage Speakers</span>
                  </a>
                </li>
               
              </ul>
            </nav>
            {/* partial */}
            <div className="main-panel" style={{ background: "white" }}>
              <Switch>
                <ProtectedRoute
                  path="/admin/private/manage-sponsors"
                  component={ManageEventSponsorsPage}
                  redirectTo="/login"
                />
                <ProtectedRoute
                  path="/admin/private/manage-speakers"
                  component={ManageEventSpeakersPage}
                  redirectTo="/login"
                />
                <ProtectedRoute
                  path="/admin/private/manage-events"
                  component={ManageEventsPage}
                  redirectTo="/login"
                />
                <ProtectedRoute
                  path="/admin/private/non-registered-users"
                  component={NonRegisteredUsersPage}
                  redirectTo="/login"
                />
                <ProtectedRoute
                  path="/admin/private"
                  component={RegistrationsDisplayPage}
                  redirectTo="/login"
                />
                <Route
                  exact
                  path="*"
                  render={() => <Redirect to="/admin/private" />}
                />
              </Switch>
              {/* <footer className="footer">
            <div className="d-sm-flex justify-content-center justify-content-sm-between">
              <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">
                Premium{" "}
                <a href="https://www.bootstrapdash.com/" target="_blank">
                  Bootstrap admin template
                </a>{" "}
                from BootstrapDash.
              </span>
              <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">
                Copyright © 2021. All rights reserved.
              </span>
            </div>
          </footer> */}
            </div>

            {/* main-panel ends */}
          </div>
          {/* page-body-wrapper ends */}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (text) => dispatch(showLoader(text)),
    hideLoader: () => dispatch(hideLoader()),
    clearUserData: () => dispatch(clearUserData()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminDashboardLayout);
