import {
  addNewSpeaker,
  deleteSpeaker,
  editSpeaker,
  getAllUpcomingEvents,
} from "../../http-calls";

const ManageSpeakerHelper = {
  getAllEvents: async () => {
    return await getAllUpcomingEvents();
  },
  addNewSpeaker: async (sponsor) => {
    await addNewSpeaker(sponsor);
  },
  deleteSpeaker: async (sponsor) => {
    await deleteSpeaker(sponsor);
  },
  editSpeaker: async (sponsor) => {
    await editSpeaker(sponsor);
  },
  categorisedEvents: (allEvents) => {
    const yearWiseCategories = {};
    allEvents.forEach((event) => {
      const startDateParts = event.start_date.split("/");
      const eventYear = startDateParts[2];
      if (!yearWiseCategories[eventYear]) {
        yearWiseCategories[eventYear] = {
          events: [],
          year: eventYear,
        };
        // yearWiseCategories[eventYear].events = [];
        // yearWiseCategories[eventYear].year = eventYear;
      }
      const eventIndex = yearWiseCategories[eventYear].events.findIndex(
        (eachEvent) => eachEvent.event_id === event.event_id
      );
      if (eventIndex === -1) {
        if (!event.formattedSpeakers) {
          event.formattedSpeakers = {}
        }
        event.formattedSpeakers = ManageSpeakerHelper._formatSpeakers(event.speakers);
        event.days = 1;
        yearWiseCategories[eventYear].events.push(event);
      } else {
        yearWiseCategories[eventYear].events[eventIndex].days++;
      }
    });
    return Object.values(yearWiseCategories).reverse();
  },
  _formatSpeakers: (speakers) => {
    const formattedSpeakers = {};
    speakers.forEach(speaker => {
      if (!formattedSpeakers[speaker.day]) {
        formattedSpeakers[speaker.day] = [];
      }
      formattedSpeakers[speaker.day].push(speaker);
    })
    return formattedSpeakers;
  }
};

export default ManageSpeakerHelper;
