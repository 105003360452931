/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import CountrySelector from "../../../components/country-selector-input/country-selector-input";
import StateSelector from "../../../components/state-selector/state-selector";

const UserForm = ({ onCancel = () => {}, onSubmit = () => {}, user = {} }) => {
  const [fields, setFields] = useState({
    email: "",
    firstName: "",
    lastName: "",
    state: "",
    zip: "",
    country: "",
    phone: "",
    companyName: "",
  });

  const _updateField = ({ fieldName, value }) => {
    const fieldsCopy = { ...fields };
    fieldsCopy[fieldName] = value;
    setFields(fieldsCopy);
  };

  const _setFields = () => {
    const fieldsCopy = { ...fields };
    // Set name
    if (user["User Name"]?.length) {
      const nameParts = user["User Name"].split(" ");
      if (nameParts[0]?.length) {
        fieldsCopy.firstName = nameParts[0];
      }
      if (nameParts[1]?.length) {
        fieldsCopy.lastName = nameParts[1];
      }
    }

    // Set email
    if (user["User Email"]?.length) {
      fieldsCopy.email = user["User Email"];
    }

    // Set state
    if (user["User State"]?.length) {
      fieldsCopy.state = user["User State"];
    }

    // Set zip
    if (user["User Zip"]?.length) {
      fieldsCopy.zip = user["User Zip"];
    }

    // Set country
    if (user["User Country"]?.length) {
      fieldsCopy.country = user["User Country"];
    }
    // Set phone
    if (user["User Phone"]?.length) {
      fieldsCopy.phone = user["User Phone"];
    }
    // Set companyName
    if (user["User Company Name"]?.length) {
      fieldsCopy.companyName = user["User Company Name"];
    }
    setFields(fieldsCopy);
  };

  const _submit = () => {
    onSubmit(fields);
  };

  useEffect(() => {
    if (user) {
      _setFields();
    }
  }, [user]);

  const {
    email,
    firstName,
    lastName,
    state,
    zip,
    country,
    phone,
    companyName,
  } = fields;

  return (
    <>
      <>
        <div className="valueDisplay">
          <div className="label">Email: {email}</div>
        </div>
        <div className="valueDisplay">
          <div className="label">First Name</div>
          <div className="value">
            {/* {selectedRegistration["User Name"]} */}
            <input
              type="text"
              className="form-control form-control-lg"
              value={firstName}
              onChange={(e) =>
                _updateField({
                  fieldName: "firstName",
                  value: e.target.value,
                })
              }
            />
          </div>
        </div>
        <div className="valueDisplay">
          <div className="label">Last Name</div>
          <div className="value">
            {/* {selectedRegistration["User Name"]} */}
            <input
              type="text"
              className="form-control form-control-lg"
              value={lastName}
              onChange={(e) =>
                _updateField({
                  fieldName: "lastName",
                  value: e.target.value,
                })
              }
            />
          </div>
        </div>

        <div className="valueDisplay">
          <div className="label">Country</div>
          <div className="value">
            <CountrySelector
              countryCode={country}
              updateCountryCode={(val) =>
                _updateField({
                  fieldName: "country",
                  value: val,
                })
              }
              onBlur={() => {}}
            />
          </div>
        </div>

        <div className="valueDisplay">
          <div className="label">State</div>
          <div className="value">
            {/* {selectedRegistration["User Phone"]} */}
            <StateSelector
              countryCode={state}
              mode={country === "United States" ? "dropdown" : "freetext"}
              updateCountryCode={(val) =>
                _updateField({
                  fieldName: "state",
                  value: val,
                })
              }
              inputClass="form-control form-control-lg"
              onBlur={() => {}}
            />
          </div>
        </div>

        <div className="valueDisplay">
          <div className="label">Phone</div>
          <div className="value">
            {/* {selectedRegistration["User Phone"]} */}
            <input
              type="text"
              className="form-control form-control-lg"
              value={phone}
              onChange={(e) =>
                _updateField({
                  fieldName: "phone",
                  value: e.target.value,
                })
              }
            />
          </div>
        </div>

        <div className="valueDisplay">
          <div className="label">Company</div>
          <div className="value">
            {/* {selectedRegistration["User Company Name"]} */}
            <input
              type="text"
              className="form-control form-control-lg"
              value={companyName}
              onChange={(e) =>
                _updateField({
                  fieldName: "companyName",
                  value: e.target.value,
                })
              }
            />
          </div>
        </div>

        <div className="valueDisplay">
          <div className="label">ZIP</div>
          <div className="value">
            {/* {selectedRegistration["User Company Name"]} */}
            <input
              type="text"
              className="form-control form-control-lg"
              value={zip}
              onChange={(e) =>
                _updateField({
                  fieldName: "zip",
                  value: e.target.value,
                })
              }
            />
          </div>
        </div>

        <div className="bottomActionBar">
          <button className="cancelBtn" onClick={onCancel}>
            Close
          </button>
          <button className="saveBtn" onClick={_submit}>
            Save
          </button>
        </div>
      </>
    </>
  );
};

export default UserForm;
