import {
  addEvent,
  updateEvent,
  deleteEvent,
  addEventSlot,
  updateEventSlot,
  deleteEventSlot,
  getAllEventsWithSlots,
} from "../../http-calls";

const ManageEventHelper = {
  getAllEventsWithSlots: async () => {
    return await getAllEventsWithSlots();
  },
  addEvent: async (eventData) => {
    return await addEvent(eventData);
  },
  updateEvent: async (eventData) => {
    await updateEvent(eventData);
  },
  deleteEvent: async (eventId) => {
    await deleteEvent(eventId);
  },
  addEventSlot: async (slotData) => {
    await addEventSlot(slotData);
  },
  updateEventSlot: async (slotData) => {
    await updateEventSlot(slotData);
  },
  deleteEventSlot: async (slotData) => {
    await deleteEventSlot(slotData);
  },

  // Updated categorisedEvents function based on the response format
  categorisedEvents: (data) => {
    console.log('data :>> ', data);
    const yearWiseEvents = [];

    // Iterate over each year key in the data
    Object.keys(data).forEach((year) => {
      const events = data[year].map((event) => ({
        event_id: event.event_id,
        event_name: event.event_name,
        description: event.description,
        isEnabled: event.isEnabled,
        slots: event.slots.map((slot) => ({
          event_slot_id: slot.event_slot_id,
          start_date: slot.start_date,
          end_date: slot.end_date,
          start_time: slot.start_time,
          end_time: slot.end_time,
          focus_id: slot.focus_id,
          webinar_id: slot.webinar_id,
        })),
        formattedDuration: `${event.slots[0].start_date} - ${event.slots[event.slots.length - 1].end_date}`, // Format the duration based on slots
      }));

      yearWiseEvents.push({
        year: year,
        events: events,
      });
    });

    return yearWiseEvents.reverse(); // Reverse to show the most recent years first
  },
};

export default ManageEventHelper;
