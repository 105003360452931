/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import CountrySelectorInput from "../../components/country-selector-input/country-selector-input";
import PhoneNumberInput from "../../components/phone-number-input/phone-number-input";
import StateSelector from "../../components/state-selector/state-selector";
import SupportForm from "../../components/support-form/support-form";
import { COUNTRIES } from "../../config/country-codes";
import FirebaseAuthService from "../../firebase-auth-service";
import { showToast } from "../../helper-methods";
import { hideLoader, showLoader } from "../../redux/actions/loader-data";
import { updateUserData } from "../../redux/actions/user-data";
import "./register-page.scss";

class RegisterPage extends Component {
  state = {
    formFields: {
      email: {
        value: "",
        isValid: false,
        isDirty: false,
      },
      firstName: {
        value: "",
        isValid: false,
        isDirty: false,
      },
      lastName: {
        value: "",
        isValid: false,
        isDirty: false,
      },
      state: {
        value: "",
        isValid: false,
        isDirty: false,
      },
      zip: {
        value: "",
        isValid: false,
        isDirty: false,
      },
      country: {
        value: "",
        isValid: false,
        isDirty: false,
      },
      phone: {
        value: "",
        isValid: false,
        isDirty: false,
      },
      companyName: {
        value: "",
        isValid: false,
        isDirty: false,
      },
    },
    isFormValid: false,
    countryCode: "+1",
    redirectTo: null,
  };

  componentDidMount() {
    FirebaseAuthService.clearUserLocally();
    this._storeRedirectParamsIfAvailable();
    if (this.props.userData.token) {
      // Already logged in
      this.props.history.replace("/");
    }
    this.props.showLoader("Loading");
    setTimeout(() => {
      this.props.hideLoader();
    }, 1000);
  }

  _updateCountry = (country) => {
    this.setState({ country });
  };

  _storeRedirectParamsIfAvailable = () => {
    const { location } = this.props;
    if (location.extras) {
      this.setState(
        {
          redirectTo: location.extras.pathname + location.extras.search,
        },
        () => {
          if (
            location.extras.pathname !== "" &&
            location.extras.pathname !== "/"
          ) {
            showToast("Please login first to continue");
          }
        }
      );
    }
  };

  _onCountryCodeUpdate = (country) => {
    const { countryCode, formFields } = this.state;
    if (
      countryCode !== country.dial_code ||
      formFields.phone.value !== country.name
    ) {
      formFields.phone.value = "";
      formFields.country.value = country.name;
      this.setState({ countryCode: country.dial_code, formFields });
    }
  };

  _getCountryCodeFromCountryName = (countryName) => {
    const selectedCountry = COUNTRIES.find((c) => c.name === countryName);
    if (selectedCountry?.dial_code?.length) {
      return selectedCountry?.dial_code;
    }
  };

  _onCountryUpdate = (country) => {
    const { formFields } = this.state;
    if (formFields.country.value !== country) {
      formFields.phone.value = "";
      let countryCode = this._getCountryCodeFromCountryName(country);
      this._updateFieldValue("country", country);
      this.setState({ countryCode, formFields });
    }
  };

  _markAsDirty = (fieldName) => {
    const { formFields } = this.state;
    formFields[fieldName].isDirty = true;
    this.setState({ formFields });
    this._validateForm();
  };

  _updateFieldValue = (fieldName, value) => {
    const { formFields } = this.state;
    formFields[fieldName].value = value;
    this.setState({ formFields });
    if (formFields[fieldName].isDirty) {
      // Validate
      this._validateForm();
    }
  };

  _updatePhoneFieldValue = (value) => {
    const { formFields } = this.state;
    formFields.phone.value = value.replace(/[^\d+]/g, "");
    this.setState({ formFields });
    if (formFields.phone.isDirty) {
      // Validate
      this._validateForm();
    }
  };

  _validateForm = () => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;
      let isFormValid = true;
      Object.keys(formFields).forEach((fieldName, index) => {
        switch (fieldName) {
          case "email": {
            var emailRegex =
              /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (emailRegex.test(String(formFields.email.value).toLowerCase())) {
              formFields.email.isValid = true;
            } else {
              formFields.email.isValid = false;
              isFormValid = false;
            }
            break;
          }
          case "password": {
            if (formFields.password.value.length >= 5) {
              formFields.password.isValid = true;
            } else {
              formFields.password.isValid = false;
              isFormValid = false;
            }
            break;
          }
          case "confirmPassword": {
            formFields.confirmPassword.isValid = true;
            formFields.confirmPassword.errorText = "";
            if (formFields.password.value && formFields.password.value.length) {
              if (
                !formFields.confirmPassword.value ||
                formFields.confirmPassword.value.length === 0
              ) {
                formFields.confirmPassword.isValid = false;
                formFields.confirmPassword.errorText =
                  "Please confirm your password";
                isFormValid = false;
              } else if (
                formFields.confirmPassword.value &&
                formFields.confirmPassword.value !== formFields.password.value
              ) {
                formFields.confirmPassword.isValid = false;
                formFields.confirmPassword.errorText = "Password didn't match";
                isFormValid = false;
              }
            }
            break;
          }
          case "firstName": {
            if (formFields.firstName.value.length >= 1) {
              formFields.firstName.isValid = true;
            } else {
              formFields.firstName.isValid = false;
              isFormValid = false;
            }
            break;
          }
          case "lastName": {
            if (formFields.lastName.value.length >= 1) {
              formFields.lastName.isValid = true;
            } else {
              formFields.lastName.isValid = false;
              isFormValid = false;
            }
            break;
          }
          case "state": {
            if (formFields.state.value.length >= 1) {
              formFields.state.isValid = true;
            } else {
              formFields.state.isValid = false;
              isFormValid = false;
            }
            break;
          }
          case "zip": {
            if (formFields.zip.value.length >= 1) {
              formFields.zip.isValid = true;
            } else {
              formFields.zip.isValid = false;
              isFormValid = false;
            }
            break;
          }
          case "country": {
            if (formFields.country.value.length >= 1) {
              formFields.country.isValid = true;
            } else {
              formFields.country.isValid = false;
              isFormValid = false;
            }
            break;
          }
          // case "phone": {
          //   if (formFields.phone.value.length >= 1) {
          //     formFields.phone.isValid = true;
          //   } else {
          //     formFields.phone.isValid = false;
          //     isFormValid = false;
          //   }
          //   break;
          // }
          case "companyName": {
            if (formFields.companyName.value.length >= 1) {
              formFields.companyName.isValid = true;
            } else {
              formFields.companyName.isValid = false;
              isFormValid = false;
            }
            break;
          }
          default: {
          }
        }
      });
      this.setState({ formFields, isFormValid }, () => {
        resolve();
      });
    });
  };

  _makeAllFieldDirty = () => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;
      Object.keys(formFields).forEach((fieldName, index) => {
        formFields[fieldName].isDirty = true;
      });
      this.setState({ formFields }, () => {
        resolve();
      });
    });
  };

  _validateAndSubmit = async (e) => {
    e.preventDefault();
    await this._makeAllFieldDirty();
    await this._validateForm();
    const { formFields, isFormValid, redirectTo, countryCode } = this.state;
    if (isFormValid) {
      try {
        // this.props.showLoader("Logging you in");
        FirebaseAuthService.generateLoginLink(formFields.email.value);
        FirebaseAuthService.storeUserLocally({
          firstName: formFields.firstName.value,
          lastName: formFields.lastName.value,
          state: formFields.state.value,
          zip: formFields.zip.value,
          country: formFields.country.value,
          phone: formFields.phone.value?.length
            ? `${countryCode}||${formFields.phone.value}`
            : "",
          companyName: formFields.companyName.value,
          email: formFields.email.value,
          redirectTo,
        });

        // await FirebaseAuthService.register(
        //   formFields.email.value,
        //   formFields.password.value
        // );
        // const firebaseClientToken =
        //   await FirebaseAuthService.getFirebaseClientToken();

        // const { data: userData } = await createUser({
        //   authToken: firebaseClientToken,
        //   firstName: formFields.firstName.value,
        //   lastName: formFields.lastName.value,
        //   state: formFields.state.value,
        //   zip: formFields.zip.value,
        //   country: formFields.country.value,
        //   phone: `${countryCode}||${formFields.phone.value}`,
        //   companyName: formFields.companyName.value,
        //   email: formFields.email.value,
        // });
        // console.log("userData :>> ", userData);
        // // Login success
        // this.props.updateUserData({
        //   ...userData.userData,
        //   token: userData.token,
        // });
        // this.props.hideLoader();
        // // Success login
        // // First check if any redirect required
        Swal.fire(
          "Verification link sent!",
          "Please check your mail inbox (including spam) to proceed further",
          "success"
        );
        this._resetForm();
        // if (redirectTo) {
        //   this.props.history.replace(redirectTo);
        // } else {
        //   this.props.history.replace("/");
        // }
      } catch (loginError) {
        console.log("loginError :>> ", loginError);
        this.props.hideLoader();
        showToast(
          loginError.message ? loginError.message : "Registration failed",
          "error"
        );
      }
    }
  };

  _resetForm = () => {
    this.setState({
      formFields: {
        email: {
          value: "",
          isValid: false,
          isDirty: false,
        },
        firstName: {
          value: "",
          isValid: false,
          isDirty: false,
        },
        lastName: {
          value: "",
          isValid: false,
          isDirty: false,
        },
        state: {
          value: "",
          isValid: false,
          isDirty: false,
        },
        zip: {
          value: "",
          isValid: false,
          isDirty: false,
        },
        country: {
          value: "",
          isValid: false,
          isDirty: false,
        },
        phone: {
          value: "",
          isValid: false,
          isDirty: false,
        },
        companyName: {
          value: "",
          isValid: false,
          isDirty: false,
        },
      },
    });
  };

  render() {
    const { formFields, countryCode } = this.state;
    return (
      <React.Fragment>
        <div className="container-scroller">
          <div className="container-fluid page-body-wrapper full-page-wrapper register-page-wrapper">
            <div className="content-wrapper d-flex align-items-center auth px-0">
              <div className="row w-100 mx-0">
                <div className="col-lg-10 mx-auto">
                  <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                    <div className="banner">
                      <img
                        src="https://dexterous-static-assets.s3.us-west-1.amazonaws.com/concorde-assets/2023_Portal_Header.jpg"
                        alt=""
                      />
                    </div>
                    <form
                      className="pt-3"
                      onSubmit={(e) => this._validateAndSubmit(e)}
                    >
                      <div className="row">
                        <div className="col-lg-6 form-group">
                          <input
                            type="text"
                            className="form-control form-control-lg"
                            name="name"
                            placeholder="First Name"
                            value={formFields.firstName.value}
                            onChange={(e) =>
                              this._updateFieldValue(
                                "firstName",
                                e.target.value
                              )
                            }
                            onBlur={() => this._markAsDirty("firstName")}
                          />
                          <div className="field-error-wrapper">
                            {formFields.firstName.isDirty &&
                            !formFields.firstName.isValid
                              ? "Required field"
                              : null}
                          </div>
                        </div>
                        <div className="col-lg-6 form-group">
                          <input
                            type="text"
                            className="form-control form-control-lg"
                            name="name"
                            placeholder="Last Name"
                            value={formFields.lastName.value}
                            onChange={(e) =>
                              this._updateFieldValue("lastName", e.target.value)
                            }
                            onBlur={() => this._markAsDirty("lastName")}
                          />
                          <div className="field-error-wrapper">
                            {formFields.lastName.isDirty &&
                            !formFields.lastName.isValid
                              ? "Required field"
                              : null}
                          </div>
                        </div>
                        <div className="col-lg-12 form-group">
                          <input
                            type="email"
                            className="form-control form-control-lg"
                            name="email"
                            placeholder="Email"
                            value={formFields.email.value}
                            onChange={(e) =>
                              this._updateFieldValue("email", e.target.value)
                            }
                            onBlur={() => this._markAsDirty("email")}
                          />
                          <div className="field-error-wrapper">
                            {formFields.email.isDirty &&
                            !formFields.email.isValid
                              ? "Please provide valid email"
                              : null}
                          </div>
                          <div className="emailNote">
                            Register using the same email address used for your
                            faasafety.gov account to have IA credit and WINGS
                            credit automatically applied.
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6 form-group">
                          <CountrySelectorInput
                            countryCode={formFields.country.value}
                            updateCountryCode={(val) =>
                              this._onCountryUpdate(val)
                            }
                            onBlur={() => {}}
                          />
                        </div>
                        <div className="col-lg-6 form-group">
                          <StateSelector
                            countryCode={formFields.state.value}
                            mode={
                              formFields.country.value === "United States"
                                ? "dropdown"
                                : "freetext"
                            }
                            updateCountryCode={(val) =>
                              this._updateFieldValue("state", val)
                            }
                            inputClass="form-control form-control-lg"
                            onBlur={() => {}}
                          />
                          <div className="field-error-wrapper">
                            {formFields.state.isDirty &&
                            !formFields.state.isValid
                              ? "Cannot be empty"
                              : null}
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-6 form-group">
                          <PhoneNumberInput
                            countryCode={countryCode}
                            updateCountryCode={this._onCountryCodeUpdate}
                            phone={formFields.phone.value}
                            updatePhoneNumber={(phone) =>
                              this._updateFieldValue("phone", phone)
                            }
                            onBlur={() => {}}
                          />
                          <div className="field-error-wrapper">
                            {formFields.phone.isDirty &&
                            !formFields.phone.isValid
                              ? "Please provide a valid phone number"
                              : null}
                          </div>
                        </div>
                        <div className="col-lg-6 form-group">
                          <input
                            type="text"
                            className="form-control form-control-lg"
                            name="companyName"
                            placeholder="Company Name"
                            value={formFields.companyName.value}
                            onChange={(e) =>
                              this._updateFieldValue(
                                "companyName",
                                e.target.value
                              )
                            }
                            onBlur={() => this._markAsDirty("companyName")}
                          />
                          <div className="field-error-wrapper">
                            {formFields.companyName.isDirty &&
                            !formFields.companyName.isValid
                              ? "Please provide a valid company name"
                              : null}
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-6 form-group">
                          <input
                            type="text"
                            className="form-control form-control-lg"
                            name="zip"
                            placeholder="ZIP"
                            value={formFields.zip.value}
                            onChange={(e) =>
                              this._updateFieldValue("zip", e.target.value)
                            }
                            onBlur={() => this._markAsDirty("zip")}
                          />
                          <div className="field-error-wrapper">
                            {formFields.zip.isDirty && !formFields.zip.isValid
                              ? "Please provide valid zip"
                              : null}
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-12">
                          <div className="disclaimerWrapper">
                            Information provided will be kept confidential.
                            Concorde Battery Corporation will not sell or share
                            this information with a third party.
                          </div>
                        </div>
                      </div>

                      <div className="mt-3">
                        <button
                          className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                          type="submit"
                        >
                          REGISTER
                        </button>
                      </div>
                      <div className="text-center mt-4 fw-light">
                        Already have an account?{" "}
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.history.push("login");
                          }}
                          className="text-primary"
                        >
                          Login
                        </a>
                      </div>
                      <SupportForm />
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* content-wrapper ends */}
          </div>
          {/* page-body-wrapper ends */}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (text) => dispatch(showLoader(text)),
    hideLoader: () => dispatch(hideLoader()),
    updateUserData: (userData) => dispatch(updateUserData(userData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterPage);
