import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";

const SpeakerCloneSelector = ({
  yearWiseEvents,
  speaker,
  yearEvent,
  yearGroup,
  onCloneSubmit,
}) => {
  const [isSelectorVisible, setIsSelectorVisible] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedDay, setSelectedDay] = useState(1);

  const _prepareEventsList = () => {
    const eventOptions = [];
    yearWiseEvents.forEach((year) => {
      year.events.forEach((event) => {
        eventOptions.push({
          eventName: event.event_name,
          eventId: event.event_id,
          days: Array.from({ length: event.days }, (_, i) => i + 1),
        });
      });
    });
    return eventOptions;
  };
  const eventOptions = _prepareEventsList();

  const _setEvent = (eventId) => {
    // console.log('eventId :>> ', eventId);
    const selectedEvent = eventOptions.find(
      (event) => event.eventId === eventId
    );
    // clo
    setSelectedEvent(selectedEvent);
  };

  const _submit = () => {
    onCloneSubmit({
      speaker_id: speaker.id,
      clone_to_event_id: selectedEvent.eventId,
      set_day: selectedDay.toString(),
    });
    setIsSelectorVisible(false);
  };

  const _reset = () => {
    setSelectedEvent(null);
    setSelectedDay(1);
  };

  useEffect(() => {
    if (!isSelectorVisible) {
      _reset();
    }
  }, [isSelectorVisible]);

  return (
    <OutsideClickHandler onOutsideClick={() => setIsSelectorVisible(false)}>
      <div className="cloneSpeakerWrapper" onClick={(e) => e.stopPropagation()}>
        <div
          className="actionButton"
          onClick={() => setIsSelectorVisible(true)}
        >
          Clone speaker
        </div>
        {isSelectorVisible ? (
          <div className="speakerClonerInnerWrapper">
            <div className="inputWrapper">
              <div className="label">Select an event</div>
              <select
                value={selectedEvent?.eventId}
                onChange={(e) => _setEvent(e.target.value)}
              >
                <option value={null} disabled={selectedEvent?.eventId}>
                  Select
                </option>
                {eventOptions.map((event) => (
                  <option value={event.eventId} key={event.eventId}>
                    {event.eventName}
                  </option>
                ))}
              </select>
            </div>
            <div className="inputWrapper">
              <div className="label">Select day</div>
              <select
                disabled={!selectedEvent}
                value={selectedDay}
                onChange={(e) => setSelectedDay(e.target.value)}
              >
                {selectedEvent?.days.map((day) => (
                  <option value={day} key={day}>
                    {day}
                  </option>
                ))}
              </select>
            </div>
            <div className="submitWrapper">
              <div
                className="dismissBtn"
                onClick={() => setIsSelectorVisible(false)}
              >
                Dismiss
              </div>
              <div className="cloneBtn" onClick={_submit}>
                Clone
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </OutsideClickHandler>
  );
};

export default SpeakerCloneSelector;
