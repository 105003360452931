import React, { useState } from "react";

const EventSlotInput = ({ onAddSlot = () => {} }) => {
  const [slotData, setSlotData] = useState({ start_time: "", end_time: "" });

  const handleTimeChange = (e) => {
    setSlotData({
      ...slotData,
      [e.target.name]: e.target.value,
    });
  };

  const handleAddSlot = () => {
    if (slotData.start_time && slotData.end_time) {
      onAddSlot(slotData);
      setSlotData({ start_time: "", end_time: "" }); // Reset fields
    }
  };

  return (
    <div className="row align-items-end addNewSlot">
      <div className="col-md-5">
        <div className="form-group mb-0">
          <label htmlFor="" className="mb-1">
            From
          </label>
          <input
            type="time"
            name="start_time"
            className="form-control"
            value={slotData.start_time}
            onChange={handleTimeChange}
          />
        </div>
      </div>
      <div className="col-md-5">
        <div className="form-group mb-0">
          <label htmlFor="" className="mb-1">
            To
          </label>
          <input
            type="time"
            name="end_time"
            className="form-control"
            value={slotData.end_time}
            onChange={handleTimeChange}
          />
        </div>
      </div>
      <div className="col-md-2 ps-0 text-end">
        <button
          className="btn btn-sm btn-success border-radius-0"
          onClick={handleAddSlot}
        >
          <i className="fa fa-plus"></i>
        </button>
      </div>
    </div>
  );
};

export default EventSlotInput;
