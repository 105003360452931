import React from "react";
import CsvDownloader from "react-csv-downloader";
import DataTable from "react-data-table-component";
import { deepClone } from "../../../helper-methods";
import {
  deleteRegistration,
  getAllRegistrations,
  getAllUpcomingEvents,
} from "../../../http-calls/index";
import EventEditorSidedock from "../../components/event-editor-sidedock/event-editor-sidedock";

import CertificateDownloader from "../../components/certificate-downloader/certificate-downloader";
import "./registrations-display-page.scss";
import moment from "moment";
import UserEditorSidedock from "../../components/user-editor-sidedock/user-editor-sidedock";

const csvFileColumns = [
  {
    id: "User Name",
    displayName: "User Name",
  },
  {
    id: "User Email",
    displayName: "Email",
  },
  {
    id: "User Phone",
    displayName: "Phone",
  },
  {
    id: "User Company Name",
    displayName: "Company Name",
  },
  {
    id: "User State",
    displayName: "State",
  },
  {
    id: "User Zip",
    displayName: "Zip",
  },
  {
    id: "User Country",
    displayName: "Country",
  },
  {
    id: "Event Name",
    displayName: "Event",
  },
  {
    id: "Event Time",
    displayName: "Event Slot",
  },
  {
    id: "Registration Date",
    displayName: "Registration Date",
  },
];

class RegistrationsDisplayPage extends React.Component {
  state = {
    allRegistrations: [],
    filteredRegistrations: [],
    eventOptions: ["All Events"],
    timeSlotOptions: ["All Slots"],
    selectedFilterEvent: "All Events",
    selectedTimeslot: "All Slots",
    searchText: "",
    allEventSlots: [],
    eventEditor: {
      isVisible: false,
      selectedRegistration: null,
    },
    userEditor: {
      isVisible: false,
      selectedRegistration: null,
    },
  };

  columns = [
    {
      name: "User Name",
      selector: (row) => row["User Name"],
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row["User Email"],
      sortable: true,
    },
    {
      name: "Phone",
      selector: (row) => row["User Phone"],
      sortable: true,
    },
    {
      name: "Company Name",
      selector: (row) => row["User Company Name"],
      sortable: true,
    },
    {
      name: "State",
      selector: (row) => row["User State"],
      sortable: true,
    },
    {
      name: "Zip",
      selector: (row) => row["User Zip"],
      sortable: true,
    },
    {
      name: "Country",
      selector: (row) => row["User Country"],
      sortable: true,
    },
    {
      name: "Event",
      selector: (row) => row["Event Name"],
      sortable: true,
    },
    {
      name: "Timeslot",
      selector: (row) => row["Event Time"],
      sortable: true,
      cell: (row, index, column, id) => (
        <div>
          {row["Event Time"]}
          <button
            onClick={(e) => this._showRegistrationEventEditor(row)}
            className="timeSlotUpdateBtn"
          >
            Change
          </button>
        </div>
      ),
    },
    {
      name: "Registration Date",
      selector: (row) => row["Registration Date"],
      sortable: true,
    },
    {
      name: "Registration Date",
      selector: (row) => row["Registration Date"],
      sortable: true,
    },
    {
      name: "Certficate",
      selector: (row) => row["User Id"],
      cell: (row, index, column, id) => (
        <CertificateDownloader registration={row} />
      ),
      sortable: true,
    },
    {
      name: "Actions",
      selector: (row) => row["User Id"],
      cell: (row, index, column, id) => (
        <>
          <button
            className="editRegBtn mr-2"
            onClick={(e) => this._showRegistrationUserEditor(row)}
          >
            <i className="fas fa-edit"></i>
          </button>
          <button
            className="deleteRegBtn"
            onClick={(e) => this._removeReg(row)}
          >
            <i className="fas fa-trash"></i>
          </button>
        </>
      ),
      sortable: true,
    },
  ];

  componentDidMount() {
    this._loadAllRegistrations();
  }

  _showRegistrationEventEditor = (registration) => {
    this.setState({
      eventEditor: {
        isVisible: true,
        selectedRegistration: registration,
      },
    });
  };

  _hideRegistrationEventEditor = () => {
    this.setState({
      eventEditor: {
        isVisible: false,
        selectedRegistration: null,
      },
    });
  };

  _showRegistrationUserEditor = (registration) => {
    this.setState({
      userEditor: {
        isVisible: true,
        selectedRegistration: registration,
      },
    });
  };

  _hideRegistrationUserEditor = () => {
    this.setState({
      userEditor: {
        isVisible: false,
        selectedRegistration: null,
      },
    });
  };

  _removeReg = async (row) => {
    try {
      if (window.confirm("Are you sure to delete the selected registration?")) {
        await deleteRegistration({ registrationId: row["User Id"] });
        const { allRegistrations } = this.state;
        const index = allRegistrations.findIndex(
          (r) => r["User Id"] === row["User Id"]
        );
        if (index > -1) {
          allRegistrations.splice(index, 1);
        }
        this.setState({ allRegistrations });
      }
    } catch (error) {}
  };

  _formatRows = (allRegistrations) => {
    return allRegistrations.map((row) => {
      let formattedRow = row;
      formattedRow["User Phone"] = row["User Phone"].replace("||", "");
      Object.keys(formattedRow).forEach((dataPoint) => {
        formattedRow[dataPoint] = formattedRow[dataPoint].replace(/,/g, " ");
        if (formattedRow[dataPoint] === "null") {
          formattedRow[dataPoint] = "--";
        }
      });
      return formattedRow;
    });
  };

  _loadAllRegistrations = async () => {
    try {
      const allRegistrations = await getAllRegistrations();
      const {
        data: { events: allEventSlots },
      } = await getAllUpcomingEvents();
      const formattedRows = this._formatRows(
        allRegistrations.data.eventRegistrations
      );
      this._generateEventOptions(formattedRows);
      this._generateTimeslotsOptions(formattedRows);
      this.setState({
        allRegistrations: formattedRows,
        allEventSlots,
      });
    } catch (error) {
      console.log("error :>> ", error);
    }
  };

  _generateEventOptions = (registrations) => {
    let eventOptions = { "All Events": true };
    registrations.forEach((r) => {
      eventOptions[r["Event Name"]] = true;
    });
    this.setState({ eventOptions: Object.keys(eventOptions) });
  };

  _getTimeSlotsForEvent = ({ eventName }) => {
    const { allEventSlots } = this.state;
    return allEventSlots.filter((slot) => slot.event_name === eventName);
  };

  _formatTimeSlot = ({ startTime, endTime }) => {
    // Before format: start_time: "08:00" , end_time: "12:00"
    // After format: "05:00 PM EST - 09:00 PM EST"
    return `${moment(startTime, "HH:mm").format("hh:mm A")} EST - ${moment(
      endTime,
      "HH:mm"
    ).format("hh:mm A")} EST`;
  };

  _generateTimeslotsOptions = (registrations) => {
    const { selectedFilterEvent } = this.state;
    // If no event is selected, keep a default option
    const timeSlotOptions = ["All Slots"];
    if (selectedFilterEvent !== "All Events") {
      // Filter time slots from eventslots
      const filteredTimeslots = this._getTimeSlotsForEvent({
        eventName: selectedFilterEvent,
      });
      filteredTimeslots.forEach((slot) => {
        const fomattedSlot = this._formatTimeSlot({
          startTime: slot.start_time,
          endTime: slot.end_time,
        });
        timeSlotOptions.push(fomattedSlot);
      });
    }

    this.setState({ timeSlotOptions });
  };

  _filter = (allRows) => {
    const excludeInSearch = ["id", "Event Name", "Event Time"];
    let filteredRows = deepClone(allRows);
    const { searchText, selectedTimeslot, selectedFilterEvent } = this.state;
    // First filter by search text
    const trimmedSearchText = searchText.trim();
    if (trimmedSearchText.length) {
      filteredRows = filteredRows.filter((r) => {
        let isValid = false;
        const allDataPoints = Object.keys(r);
        allDataPoints.forEach((dataPoint) => {
          if (excludeInSearch.indexOf(dataPoint) === -1) {
            if (
              r[dataPoint]
                .toLowerCase()
                .indexOf(trimmedSearchText.toLowerCase()) > -1
            ) {
              isValid = true;
            }
          }
        });
        return isValid;
      });
    }
    if (selectedFilterEvent !== "All Events") {
      // Filter by selectevent
      filteredRows = filteredRows.filter(
        (r) => r["Event Name"] === selectedFilterEvent
      );
      if (selectedTimeslot !== "All Slots") {
        // Filter by selectevent
        filteredRows = filteredRows.filter(
          (r) => r["Event Time"] === selectedTimeslot
        );
      }
    }
    return filteredRows;
  };

  _updateRegistrationDetails = (params) => {
    console.log("params :>> ", params);
    if (params?.registrationId?.length) {
      const { allRegistrations } = this.state;
      let registrationIndex = allRegistrations.findIndex(
        (reg) => reg.registrationId === params.registrationId
      );
      if (registrationIndex > -1) {
        allRegistrations[registrationIndex] = {
          ...allRegistrations[registrationIndex],
          ...params,
        };
        this.setState({
          allRegistrations,
        });
      }
    }
    this._hideRegistrationEventEditor();
    this._hideRegistrationUserEditor();
  };

  render() {
    const {
      allRegistrations,
      timeSlotOptions,
      eventOptions,
      selectedFilterEvent,
      selectedTimeslot,
      searchText,
      eventEditor,
      userEditor,
    } = this.state;

    const filteredRows = this._filter(allRegistrations);

    return (
      <div className="adminPageWrapper">
        <div className="pageTitle">All Registrations</div>
        <div className="tableActions">
          <div className="leftPart">
            <input
              type="text"
              placeholder="Search"
              value={searchText}
              onChange={(e) => this.setState({ searchText: e.target.value })}
            />
          </div>
          <div className="rightPart">
            <select
              value={selectedFilterEvent}
              onChange={(e) =>
                this.setState(
                  {
                    selectedFilterEvent: e.target.value,
                    selectedTimeslot: "All Slots",
                  },
                  () => {
                    this._generateTimeslotsOptions();
                  }
                )
              }
            >
              {eventOptions.map((event, eventIndex) => (
                <option value={event} key={eventIndex}>
                  {event}
                </option>
              ))}
            </select>
            {selectedFilterEvent !== "All Events" ? (
              <select
                value={selectedTimeslot}
                onChange={(e) =>
                  this.setState({ selectedTimeslot: e.target.value })
                }
              >
                {timeSlotOptions.map((timeSlot, timeSlotIndex) => (
                  <option value={timeSlot} key={timeSlotIndex}>
                    {timeSlot}
                  </option>
                ))}
              </select>
            ) : (
              <></>
            )}
            {filteredRows?.length ? (
              <CsvDownloader
                filename="concorde-registrations"
                separator=","
                wrapColumnChar=""
                columns={csvFileColumns}
                datas={filteredRows}
                text="DOWNLOAD"
              >
                <button>Download CSV</button>
              </CsvDownloader>
            ) : (
              <></>
            )}
          </div>
        </div>
        <DataTable
          columns={this.columns}
          data={filteredRows}
          fixedHeader
          pagination
          paginationPerPage={100}
          paginationRowsPerPageOptions={[100, 500, 1000]}
        />
        <EventEditorSidedock
          isVisible={eventEditor.isVisible}
          selectedRegistration={eventEditor.selectedRegistration}
          onDismiss={this._hideRegistrationEventEditor}
          onUpdate={this._updateRegistrationDetails}
        />
        <UserEditorSidedock
          isVisible={userEditor.isVisible}
          selectedRegistration={userEditor.selectedRegistration}
          onDismiss={this._hideRegistrationUserEditor}
          onUserUpdate={this._updateRegistrationDetails}
        />
      </div>
    );
  }
}

export default RegistrationsDisplayPage;
