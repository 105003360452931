class LibraryScripts {
  static runLibraryScripts = () => {
    setTimeout(() => {
      const script = document.createElement("script");

      script.src = "/js/template.js";
      script.async = true;

      document.body.appendChild(script);

      const script2 = document.createElement("script");

      script2.src = "/js/off-canvas.js";
      script2.async = true;

      document.body.appendChild(script2);
    }, 100);
  };
}

export default LibraryScripts;
