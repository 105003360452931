import React, { useEffect, useState } from "react";
import { RotatingLines } from "react-loader-spinner";
import OutsideClickHandler from "react-outside-click-handler";
import { deepClone } from "../../../helper-methods";
import Swal from "sweetalert2";

import { eventRegistration, getAllUpcomingEvents, updateTimeslot } from "../../../http-calls";
import "./add-to-events-sidedock.scss";

const AddToEventsSidedock = ({
  isVisible,
  selectedUser,
  onDismiss = () => {},
  onUpdate = () => {},
}) => {
  const [isLoaderActive, setIsLoaderActive] = useState(false);
  const [allEvents, setAllEvents] = useState([]);
  const [selectedEventSlots, setSelectedEventSlots] = useState({});

  const _fetchAllEvents = async () => {
    const {
      data: { events: allEventSlots },
    } = await getAllUpcomingEvents();
    const allEvents = {};
    const currentYear = '2024';

    allEventSlots.forEach((eventSlot) => {
      if (eventSlot.event_name.indexOf(currentYear) > -1) {
        if (!allEvents[eventSlot.event_id]) {
          allEvents[eventSlot.event_id] = {
            event_name: eventSlot.event_name,
            event_id: eventSlot.event_id,
            isEnabled: eventSlot.isEnabled,
            slots: [],
          };
        }
        const formattedEventSlot = _formatEventSlot(eventSlot);
        allEvents[eventSlot.event_id].slots.push(formattedEventSlot);
      }
    });
    // console.log('data', data)
    setAllEvents(Object.values(allEvents));
  };

  const _formatEventSlot = (eventSlot) => {
    return {
      ...eventSlot,
      label: `${convert24To12(eventSlot.start_time)} EST - ${convert24To12(
        eventSlot.end_time
      )} EST`,
    };
  };

  const _toggleEventSlotSelection = ({ eventSlot, event }) => {
    console.log("eventSlot", eventSlot);
    let _selectedEventSlots = deepClone(selectedEventSlots);
    if (_selectedEventSlots[eventSlot.event_slot_id]) {
      delete _selectedEventSlots[eventSlot.event_slot_id];
    } else {
      // Check if selected for same event
      let otherSlotIdFromSameEvent = null;
      const allSlotIds = event.slots.map((slot) => slot.event_slot_id);
      allSlotIds.forEach((allSlotId) => {
        if (_selectedEventSlots[allSlotId]) {
          otherSlotIdFromSameEvent = allSlotId;
        }
      });
      if (otherSlotIdFromSameEvent) {
        delete _selectedEventSlots[otherSlotIdFromSameEvent];
      }
      _selectedEventSlots[eventSlot.event_slot_id] = eventSlot;
    }
    setSelectedEventSlots(_selectedEventSlots);
  };


  const convert24To12 = (time24) => {
    var ts = time24;
    var H = +ts.substr(0, 2);
    var h = H % 12 || 12;
    h = h < 10 ? "0" + h : h; // leading 0 at the left for 1 digit hours
    var ampm = H < 12 ? " AM" : " PM";
    ts = h + ts.substr(2, 3) + ampm;
    return ts;
  };

  const _preparePayload = () => {
    return Object.values(selectedEventSlots).map(eventSlot => ({
      userId: selectedUser.user_id,
      eventId: eventSlot.event_id,
      eventSlotId: eventSlot.event_slot_id,
      focusId: eventSlot.focus_id,
    }));
  };

  const _saveChanges = async () => {
    setIsLoaderActive(true);
    const allPayloads = _preparePayload();
    try {
      if (allPayloads.length) {
        await Promise.allSettled(allPayloads.map(payload => eventRegistration(payload)))
        Swal.fire(
          "Successfully registered to the events",
          "You can check the registration details in the 'All Regsitrations' section",
          "success"
        );
        onUpdate();
      }
    } catch (error) {
      console.log('error', error)
      Swal.fire(
        "Unable to register to the events",
        "",
        "error"
      );
    }
    setIsLoaderActive(false);
  };

  const _tryToDismiss = () => {
    if (!isLoaderActive) {
      onDismiss();
    } else {
      // alert('Please wai')
    }
  };

  useEffect(() => {
    _fetchAllEvents();
  }, []);

  useEffect(() => {
    if (selectedUser) {
      setIsLoaderActive(false);
    }
  }, [selectedUser]);

  useEffect(() => {
    if (!isVisible) {
      setSelectedEventSlots({});
    }
  }, [isVisible]);

  console.log("selectedUser :>> ", selectedUser);

  return (
    <>
      <div className={`eventSideBarOuterWrapper ${isVisible ? "show" : ""}`}>
        <OutsideClickHandler onOutsideClick={_tryToDismiss}>
          <div className={`eventSideBarWrapper ${isVisible ? "show" : ""}`}>
            {isLoaderActive ? (
              <div className="loaderWrapper">
                <RotatingLines
                  strokeColor="grey"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="96"
                  visible={true}
                />
              </div>
            ) : (
              <>
                {selectedUser ? (
                  <>
                    <div className="valueDisplay">
                      <div className="label">Name</div>
                      <div className="value">{selectedUser?.name}</div>
                    </div>

                    {/* <div className="valueDisplay">
                      <div className="label">Email</div>
                      <div className="value">{selectedUser?.email}</div>
                    </div>

                    <div className="valueDisplay">
                      <div className="label">Phone</div>
                      <div className="value">{selectedUser?.phone}</div>
                    </div>

                    <div className="valueDisplay">
                      <div className="label">Company</div>
                      <div className="value">{selectedUser?.company_name}</div>
                    </div> */}

                    {allEvents?.map((event) => (
                      <React.Fragment key={event.event_id}>
                        <div className="valueDisplay">
                          <div className="label eventName">
                            {event?.event_name}
                          </div>
                        </div>

                        <div className="valueDisplay">
                          {/* <div className="label">Timeslot</div> */}
                          <div className="slotSelector">
                            {event?.slots?.map((slot) => (
                              <div
                                className={`slot ${
                                  selectedEventSlots[slot.event_slot_id]
                                    ? "active"
                                    : ""
                                }`}
                                key={slot.label}
                                onClick={(e) =>
                                  _toggleEventSlotSelection({
                                    eventSlot: slot,
                                    event,
                                  })
                                }
                              >
                                {slot.label}
                              </div>
                            ))}
                          </div>
                        </div>
                      </React.Fragment>
                    ))}
                  </>
                ) : (
                  <></>
                )}
                <div className="bottomActionBar">
                  <button className="cancelBtn" onClick={_tryToDismiss}>
                    Close
                  </button>
                  <button
                    className="saveBtn"
                    disabled={Object.keys(selectedEventSlots).length < 1}
                    onClick={_saveChanges}
                  >
                    Register
                  </button>
                </div>
              </>
            )}
          </div>
        </OutsideClickHandler>
      </div>
    </>
  );
};

export default AddToEventsSidedock;
