import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { toggleFeaturedSponsor } from "../../../http-calls";
import { hideLoader, showLoader } from "../../../redux/actions/loader-data";
import { store } from "../../../redux/store";
import FeaturedSponsorSelector from "../../components/featured-sponsor-selector/featured-sponsor-selector";
import SponsorDetailsEditor from "../../components/sponsor-details-editor/sponsor-details-editor";
import ManageSponsorHelper from "../../helpers/manage-sponsor-helper";
import "./manage-event-sponsors-page.scss";

const ManageEventSponsorsPage = () => {
  const [yearWiseEvents, setYearWiseEvents] = useState([]);
  const [editorOptions, setEditorOptions] = useState({
    isVisible: false,
    mode: "add",
    selectedSponsor: null,
    selectedEventId: null,
  });

  const _fetchAndFormatEvents = async () => {
    store.dispatch(showLoader("Loading"));
    try {
      const {
        data: { events },
      } = await ManageSponsorHelper.getAllEvents();
      const yearWiseEvents = ManageSponsorHelper.categorisedEvents(events);
      setYearWiseEvents(yearWiseEvents);
    } catch (error) {
      console.log("error", error);
    }
    store.dispatch(hideLoader("Loading"));
  };

  const _showEditModal = (sponsor) => {
    setEditorOptions({
      isVisible: true,
      mode: "edit",
      selectedSponsor: sponsor,
      selectedEventId: sponsor.event_id,
    });
  };

  const _showCreateModal = (event_id) => {
    const sponsor = {
      event_id,
      logo: "",
      name: "",
      event_name: "",
      event_details: "",
      presenter_name: "",
      presenter_details: "",
      contact_email: "",
      contact_website: "",
    };
    setEditorOptions({
      isVisible: true,
      mode: "add",
      selectedSponsor: sponsor,
      selectedEventId: event_id,
    });
  };

  const _hideEditor = () => {
    setEditorOptions({
      isVisible: false,
      mode: "add",
      selectedSponsor: null,
      selectedEventId: null,
    });
  };

  const _onUpdate = () => {
    _fetchAndFormatEvents();
  };

  const _updateFeaturedSponsor = async (payload) => {
    store.dispatch(showLoader("Loading"));
    await toggleFeaturedSponsor(payload);
    _fetchAndFormatEvents();
  };

  useEffect(() => {
    _fetchAndFormatEvents();
  }, []);


  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      {editorOptions.isVisible ? (
        <SponsorDetailsEditor
          mode={editorOptions.mode}
          sponsor={editorOptions.selectedSponsor}
          onUpdate={_onUpdate}
          onDismiss={_hideEditor}
          isVisible={editorOptions.isVisible}
        />
      ) : (
        <></>
      )}

      <div className="manageEventSponsorsPageWrapper">
        <div>
          <h1>Manage Event Sponsors</h1>

          {yearWiseEvents?.map((yearGroup) => (
            <div className="row pb-4 mb-4" key={yearGroup.year}>
              <div className="col">
                <h2>{`${yearGroup.year - 1} - ${yearGroup.year}`} Events</h2>
                <div className="tabs">
                  {yearGroup?.events?.map((yearEvent) => (
                    <div className="tab" key={yearEvent.event_id}>
                      <input type="checkbox" id={yearEvent.event_id} />
                      <label className="tab-label" htmlFor={yearEvent.event_id}>
                        {yearEvent.event_name}
                      </label>
                      <div className="tab-content">
                        <div className="sponsorsListWrapper">
                          <div className="container">
                            <div className="addNewSponsor">
                              <button
                                onClick={() =>
                                  _showCreateModal(yearEvent.event_id)
                                }
                              >
                                Add New Sponsor
                              </button>
                            </div>
                            <div className="row">
                              {yearEvent?.sponsors?.map((sponsor) => (
                                <div
                                  className="col-lg-6"
                                  key={sponsor.id}
                                  onClick={() => _showEditModal(sponsor)}
                                >
                                  <div className="sponsor">
                                    <img
                                      src={
                                        sponsor.logo?.length
                                          ? sponsor.logo
                                          : "https://www.westwords.com.au/wp-content/uploads/2022/05/img-placeholder.png"
                                      }
                                      alt=""
                                    />
                                    <FeaturedSponsorSelector
                                      sponsor={sponsor}
                                      event={yearEvent}
                                      onUpdate={_updateFeaturedSponsor}
                                    />
                                    <div className="sponsorName">
                                      {sponsor.name}
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default ManageEventSponsorsPage;
