/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import Modal from "react-awesome-modal";
import RichTextEditor from "../../../components/rich-text-editor/rich-text-editor";
import { useFormFields } from "../../../custom-hooks/use-form-fields";
import { deepClone, showToast } from "../../../helper-methods";
import { showLoader } from "../../../redux/actions/loader-data";
import { store } from "../../../redux/store";
import ManageSpeakerHelper from "../../helpers/manage-speaker-helper";
import ImageEditor from "../image-editor/image-editor";

import "./speaker-details-editor.scss";

export const formFields = {
  logo: "",
  name: "",
  event_name: "",
  event_details: "",
  presenter_name: "",
  presenter_details: "",
  contact_email: "",
  contact_website: "",
  youtube_video_link: "",
  day: "1"
};

const SpeakerDetailsEditor = React.memo(
  ({
    mode = "add",
    sponsor = null,
    onUpdate = () => {},
    onDismiss = () => {},
    isVisible = false,
  }) => {
    const { fields, updateField, updateFields } = useFormFields(formFields);
    const [isReady, setIsReady] = useState(false);

    const _validateAndSave = async () => {
      if (!fields.name.length) {
        return showToast("Company name is mandatory");
      }
      if (!fields.logo.length) {
        return showToast("Company logo is mandatory");
      }
      store.dispatch(showLoader("Loading"));
      if (mode === "add") {
        await ManageSpeakerHelper.addNewSpeaker({
          ...sponsor,
          ..._prepareSQLSafeFields(fields),
        });
      } else {
        await ManageSpeakerHelper.editSpeaker({
          ...sponsor,
          ..._prepareSQLSafeFields(fields),
        });
      }
      onDismiss();
      onUpdate();
    };

    const _prepareSQLSafeFields = (fields) => {
      for (let fieldKey in fields) {
        fields[fieldKey] = fields[fieldKey].replace(/'/g, "\\'");;
      }
      return fields;
    }

    const _setFields = () => {
      if (sponsor) {
        setIsReady(false);
        const sponsorCopy = deepClone(sponsor);
        delete sponsorCopy.id;
        delete sponsorCopy.event_id;
        delete sponsorCopy.created_on;
        delete sponsorCopy.last_updated_on;
        updateFields(sponsorCopy);
        setTimeout(() => {
          setIsReady(true);
        }, 300);
      }
    };

    const _delete = async () => {
      var result = window.confirm(
        `Are you sure to delete sponsor ${sponsor.name}?`
      );
      if (result) {
        await ManageSpeakerHelper.deleteSpeaker(sponsor);
      }
      onDismiss();
      onUpdate();
    };

    useEffect(() => {
      if (isVisible) {
        _setFields();
      }
    }, [isVisible, sponsor]);


    return (
      <>
        <div className="sponsorDetailsEditorModal">
          <Modal
            visible={isVisible}
            width="1000"
            height="95%"
            effect="fadeInUp"
            onClickAway={onDismiss}
          >
            <div className="header">
              <div className="leftPart">Speaker Editor</div>
              <div className="rightPart">
                <button className="actionBtns deleteBtn" onClick={_delete}>
                  Delete
                </button>
                <button
                  className="actionBtns saveBtn"
                  onClick={_validateAndSave}
                >
                  Save
                </button>
                <button className="actionBtns cancelBtn" onClick={onDismiss}>
                  Cancel
                </button>
              </div>
            </div>
            <div className="container-fluid sponsorDetailsEditorInnerWrapper">
              <div className="row">
                <div className="col-lg-6">
                  <ImageEditor
                    value={fields.logo}
                    onChange={(value) => updateField("logo", value)}
                  />
                </div>
                <div className="col-lg-6">
                  <div className="inputWrapper">
                    <div className="label">Company Name *</div>
                    <input
                      type="text"
                      value={fields.name}
                      onChange={(e) =>
                        updateField("name", e.target.value)
                      }
                    />
                  </div>
                  <div className="inputWrapper">
                    <div className="label">Day *</div>
                    <select value={fields.day} onChange={(e) =>
                        updateField("day", e.target.value)
                      }>
        <option value="1">1</option>
        <option value="2">2</option>
      </select>
                    {/* <input
                      type="text"
                      value={fields.name}
                      onChange={(e) =>
                        updateField("name", e.target.value)
                      }
                    /> */}
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="inputWrapper">
                    <div className="label">Course Title</div>

                    <input
                      type="text"
                      value={fields.event_name}
                      onChange={(e) =>
                        updateField("event_name", e.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="inputWrapper">
                    <div className="label">Youtube video link</div>

                    <input
                      type="text"
                      value={fields.youtube_video_link}
                      onChange={(e) =>
                        updateField("youtube_video_link", e.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="inputWrapper">
                    <div className="label">Course Description</div>

                    <RichTextEditor
                      value={fields.event_details}
                      onChange={(value) =>
                        isReady && updateField("event_details", value)
                      }
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="inputWrapper">
                    <div className="label">Presenter Name</div>

                    <input
                      type="text"
                      value={fields.presenter_name}
                      onChange={(e) =>
                        updateField("presenter_name", e.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="inputWrapper">
                    <div className="label">Presenter Details</div>

                    <RichTextEditor
                      value={fields.presenter_details}
                      onChange={(value) =>
                        isReady && updateField("presenter_details", value)
                      }
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="inputWrapper">
                    <div className="label">Website</div>

                    <input
                      type="text"
                      value={fields.contact_website}
                      onChange={(e) =>
                        updateField("contact_website", e.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="inputWrapper">
                    <div className="label">Contact</div>

                    <input
                      type="text"
                      value={fields.contact_email}
                      onChange={(e) =>
                        updateField("contact_email", e.target.value)
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </>
    );
  }
);

export default SpeakerDetailsEditor;
