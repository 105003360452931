import React, { useCallback, useEffect, useState } from "react";
import { RotatingLines } from "react-loader-spinner";
import OutsideClickHandler from "react-outside-click-handler";
import { getAllUpcomingEvents, updateTimeslot } from "../../../http-calls";
import "./event-editor-sidedock.scss";

const EventEditorSidedock = ({
  isVisible,
  selectedRegistration,
  onDismiss = () => {},
  onUpdate = () => {},
}) => {
  const [isLoaderActive, setIsLoaderActive] = useState(false);
  const [allEvents, setAllEvents] = useState([]);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState();

  const _fetchAllEvents = async () => {
    const {
      data: { events },
    } = await getAllUpcomingEvents();
    // console.log('data', data)
    setAllEvents(events);
  };

  const _updateSelectedTimeSlot = useCallback(() => {
    setSelectedTimeSlot(selectedRegistration.eventSlotId);
  }, [selectedRegistration])

  const _generateTimeSlotOptions = () => {
    const options = [];
    if (selectedRegistration) {
      allEvents.forEach((event) => {
        if (event.event_id === selectedRegistration.eventId) {
          options.push({
            label: `${convert24To12(event.start_time)} EST - ${convert24To12(
              event.end_time
            )} EST`,
            ...event,
          });
        }
      });
    }
    return options;
  };

  const convert24To12 = (time24) => {
    var ts = time24;
    var H = +ts.substr(0, 2);
    var h = H % 12 || 12;
    h = h < 10 ? "0" + h : h; // leading 0 at the left for 1 digit hours
    var ampm = H < 12 ? " AM" : " PM";
    ts = h + ts.substr(2, 3) + ampm;
    return ts;
  };

  const _saveChanges = async () => {
    setIsLoaderActive(true);
    // Check if timeslots are changed
    if (selectedTimeSlot !== selectedRegistration.eventSlotId) {
      const selectedEvent = allEvents.find(
        (event) => selectedTimeSlot === event.event_slot_id
      );
      const {
        data: { registration: updatedRegistration },
      } = await updateTimeslot({
        newFocusId: selectedEvent.focus_id,
        newTimeSlotId: selectedEvent.event_slot_id,
        eventId: selectedEvent.event_id,
        userId: selectedRegistration.userId,
        registrationId: selectedRegistration.registrationId,
      });
      setIsLoaderActive(false);
      onUpdate({
        focusId: selectedEvent.focus_id,
        eventSlotId: selectedEvent.event_slot_id,
        "Join URL": updatedRegistration.joinURL,
        "Event Time": `${convert24To12(
          selectedEvent.start_time
        )} EST - ${convert24To12(selectedEvent.end_time)} EST`,
        registrationId: selectedRegistration.registrationId,
      });
    }
  };

  const _tryToDismiss = () => {
    if (!isLoaderActive) {
      onDismiss();
    } else {
      // alert('Please wai')
    }
  };

  useEffect(() => {
    _fetchAllEvents();
  }, []);

  useEffect(() => {
    if (selectedRegistration) {
      setIsLoaderActive(false);
      _updateSelectedTimeSlot();
    }
  }, [_updateSelectedTimeSlot, selectedRegistration]);

  const timeSlotOptions = _generateTimeSlotOptions();
  return (
    <>
      <div className={`eventSideBarOuterWrapper ${isVisible ? "show" : ""}`}>
        <OutsideClickHandler onOutsideClick={_tryToDismiss}>
          <div className={`eventSideBarWrapper ${isVisible ? "show" : ""}`}>
            {isLoaderActive ? (
              <div className="loaderWrapper">
                <RotatingLines
                  strokeColor="grey"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="96"
                  visible={true}
                />
              </div>
            ) : (
              <>
                {selectedRegistration ? (
                  <>
                    <div className="valueDisplay">
                      <div className="label">Name</div>
                      <div className="value">
                        {selectedRegistration["User Name"]}
                      </div>
                    </div>

                    <div className="valueDisplay">
                      <div className="label">Email</div>
                      <div className="value">
                        {selectedRegistration["User Email"]}
                      </div>
                    </div>

                    <div className="valueDisplay">
                      <div className="label">Phone</div>
                      <div className="value">
                        {selectedRegistration["User Phone"]}
                      </div>
                    </div>

                    <div className="valueDisplay">
                      <div className="label">Company</div>
                      <div className="value">
                        {selectedRegistration["User Company Name"]}
                      </div>
                    </div>

                    <div className="valueDisplay">
                      <div className="label">Event</div>
                      <div className="value">
                        {selectedRegistration["Event Name"]}
                      </div>
                    </div>

                    <div className="valueDisplay">
                      <div className="label">Timeslot</div>
                      <div className="slotSelector">
                        {timeSlotOptions?.map((option) => (
                          <div
                            className={`slot ${
                              selectedTimeSlot === option.event_slot_id
                                ? "active"
                                : ""
                            }`}
                            key={option.label}
                            onClick={(e) =>
                              setSelectedTimeSlot(option.event_slot_id)
                            }
                          >
                            {option.label}
                          </div>
                        ))}
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                <div className="bottomActionBar">
                  <button className="cancelBtn" onClick={_tryToDismiss}>
                    Close
                  </button>
                  <button
                    className="saveBtn"
                    disabled={
                      selectedTimeSlot === selectedRegistration?.eventSlotId
                    }
                    onClick={_saveChanges}
                  >
                    Save
                  </button>
                </div>
              </>
            )}
          </div>
        </OutsideClickHandler>
      </div>
    </>
  );
};

export default EventEditorSidedock;
