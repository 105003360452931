/* eslint-disable react-hooks/exhaustive-deps */
import { post } from "axios";
import React, { useEffect, useRef, useState } from "react";
import "./image-editor.scss";
import { uploadFile } from "../../../http-calls";

const ImageEditor = ({
  value = "",
  onChange = () => {},
  onUploadStart = () => {},
  onUploadEnd = () => {},
}) => {
  const [logoFile, setLogoFile] = useState(null);
  const inputUpdateAvatarPhoto = useRef(null);

  const _onFileChange = (event) => {
    const files = inputUpdateAvatarPhoto.current?.files;
    // make sure that it's not null or undefined
    if (files) {
      //   const formData = new FormData();
      //   formData.append("avatar", files[0]);
      const file = files[0];
      setLogoFile(file);
    }
  };

  const uploadToCloudinary = async (file) => {
    onUploadStart();
    const url = "https://api.cloudinary.com/v1_1/dyfzooy3n/image/upload";
    const preset = "wqav5eof";

    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", preset);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    try {
      const response = await post(url, formData, config);
      onChange(response.data.secure_url);
    } catch (error) {
      return "";
    }
    inputUpdateAvatarPhoto.current.value = null;
    onUploadEnd();
    setLogoFile(null);
  };

  const uploadToS3 = async (file) => {
    // Upload to s3 using api
    onUploadStart();
    // Create multipart form data
    const formData = new FormData();
    formData.append("file", file);
    // Call api
    try {
      const response = await uploadFile(formData);
      if (response.data?.fileUrl) {
        onChange(response.data.fileUrl);
      }
      // onChange(fileUrl);
    } catch (error) {
      console.log("error :>> ", error);
    }
  };

  const _generateImagePreview = () => {
    if (logoFile) {
      return URL.createObjectURL(logoFile);
    } else {
      if (value?.length) {
        return value;
      } else {
        return "https://content.app-sources.com/s/27003911159259653/thumbnails/640x480/Logo/placeholder-logo-2-1800776.png";
      }
    }
  };

  useEffect(() => {
    if (logoFile) {
      uploadToS3(logoFile);
    }
  }, [logoFile]);

  const imagePreview = _generateImagePreview();
  console.log("imagePreview :>> ", imagePreview);

  return (
    <>
      <div
        className="sponsorImageEditorWrapper"
        onClick={() => {
          inputUpdateAvatarPhoto.current.click();
        }}
      >
        <img src={imagePreview} alt="" />
        <div className="hintText">Click here to upload new logo</div>
        <input
          id="myInput"
          type="file"
          ref={inputUpdateAvatarPhoto}
          style={{ display: "none" }}
          onChange={_onFileChange}
        />
      </div>
    </>
  );
};

export default ImageEditor;
