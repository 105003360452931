import React, { useState } from "react";
import {
  Button,
  DropdownMenu,
  DropdownToggle,
  Input,
  InputGroup,
  InputGroupButtonDropdown,
} from "reactstrap";
import { COUNTRIES } from "../../config/country-codes/index";
import STATES from "../../config/states.json";
import { deepClone } from "../../helper-methods";
import styles from "./state-selector.module.scss";

const StateSelector = (props) => {
  const [splitButtonOpen, setSplitButtonOpen] = useState(false);
  const [searchedText, updateSearchedText] = useState("");
  let allCountries = deepClone(STATES);

  // Check if any country code is passed from props
  const { countryCode = "", mode, onBlur, inputClass } = props;
  let selectedCountry = null;
  if (countryCode && countryCode.length) {
    const selectedCountryIndex = allCountries.findIndex(
      (country) => country.value === countryCode
    );
    selectedCountry = deepClone(allCountries[selectedCountryIndex]);
    // Remove that country from list
    // allCountries.splice(selectedCountryIndex, 1);
  }
  // Filter
  if (searchedText.trim().length) {
    const loweredSearchText = searchedText.trim().toLowerCase();
    allCountries = allCountries.filter((country) => {
      if (country.value.toLowerCase().indexOf(loweredSearchText) > -1) {
        return true;
      }
    });
  }
  // Add that in the begining
  // if (selectedCountry) {
  //   allCountries = [selectedCountry].concat(allCountries);
  // }

  const { updateCountryCode } = props;
  const toggleSplit = () => {
    if (splitButtonOpen) {
      // Reset search text
      updateSearchedText("");
    }
    setSplitButtonOpen(!splitButtonOpen);
  };
  const currentCountry = allCountries.find((c) => c.value === countryCode);
  if (mode === "freetext") {
    return (
      <input
        type="text"
        className={inputClass}
        placeholder="State"
        value={countryCode}
        onChange={(e) => updateCountryCode(e.target.value)}
        onBlur={onBlur}
      />
    );
  } else {
    return (
      <div className={styles.phoneNumberWrapper + ' phoneSelector'}>
        <InputGroup>
          <InputGroupButtonDropdown
            addonType="prepend"
            isOpen={splitButtonOpen}
            toggle={toggleSplit}
          >
            <DropdownToggle caret className="countryLabel">
              <div className="countrySelectionLabel">
                {currentCountry ? (
                  <>
                    <div>{currentCountry.value}</div>
                  </>
                ) : (
                  <div className="noCountrySelected">State</div>
                )}
              </div>
            </DropdownToggle>
            <DropdownToggle split outline className={styles.dropDownTrigger} />
            <DropdownMenu style={{ lefy: "-43px" }}>
              <div className={styles.flagsViewerSearch}>
                <input
                  placeholder="Search states "
                  autoFocus
                  value={searchedText}
                  onChange={(e) => updateSearchedText(e.target.value)}
                />
              </div>
              <div className={styles.flagsViewerWrapper}>
                {allCountries.map((country, countryIndex) => (
                  <div
                    className={
                      styles.flagViewerWrapper +
                      (countryIndex === 0 ? " " + styles.selected : "")
                    }
                    key={countryIndex}
                    onClick={(e) => {
                      toggleSplit();
                      updateCountryCode(country.value);
                    }}
                  >
                    <div>{country.value}</div>
                  </div>
                ))}
              </div>
            </DropdownMenu>
          </InputGroupButtonDropdown>
        </InputGroup>
      </div>
    );
  }
};

export default StateSelector;
