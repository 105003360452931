import React, { useState } from "react";
import { useEffect } from "react";
import EventViewer from "../event-viewer/event-viewer";
import "./events-tabbed-view.scss";

const EventsTabbedview = ({ allEvents, onEventRegistrationSuccess }) => {
  const [selectedYear, setSelectedYear] = useState();
  const [selectableYears, setSelectableYears] = useState([]);

  const _grabYearFromDate = (date) => {
    let year = null;
    const dateParts = date.split('/');
    if (dateParts.length === 3) {
      year = dateParts[2];
    }
    return year;
  }

  const _generateTabYears = () => { 
    let yearsMap = {};
    allEvents.forEach(event => {
      if (event?.endDate?.length) {
        const year = _grabYearFromDate(event.startDate);
        if (year) {
          yearsMap[year] = true;
        }
      }
    })
    const years = Object.keys(yearsMap).sort((y1, y2) => y2 - y1);
    setSelectableYears(years);
    if (years[0]) {
      setSelectedYear(years[0]);
    }
  }

  const _filterEvents = () => {
    return allEvents.filter(event => event.startDate.indexOf(selectedYear) > -1)
  }

  useEffect(() => {
    if (allEvents?.length) {
      _generateTabYears();
    }
  }, [allEvents]);

  const filteredEvents = _filterEvents();

  return (
    <>
      <div className="content-wrapper registered-events-page-outer-wrapper">
        <div className="yearTabsWrapper">
          {selectableYears?.map((selectableYear) => (
            <div
              className={`yearTab ${
                selectableYear === selectedYear ? "active" : ""
              }`}
              onClick={(e) => setSelectedYear(selectableYear)}
            >
              {selectableYear}
            </div>
          ))}
        </div>
        <div className="row">
          <div className="col-sm-12">
            <div className="cardsWrapper">
              {filteredEvents.map((event) => (
                <React.Fragment key={event.eventId}>
                  <EventViewer
                    event={event}
                    onSuccess={onEventRegistrationSuccess}
                    showRegisteredActions
                  />
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EventsTabbedview;
