import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Modal from "react-awesome-modal";
import Swal from "sweetalert2";
import { sendRegistrationConfirmationMail } from "../../http-calls";

import "./support-form.scss";

const SupportForm = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const _validateAndSubmit = async (e) => {
    setIsSubmitting(true);
    let isValid = false;
    if (name?.length && message?.length && email?.length) {
      isValid = true;
    }
    if (isValid) {
      try {
        await sendRegistrationConfirmationMail({
          EmailContent: `Message: ${message}, user email: ${email}`,
          ToEmail: "support@dexteroustechnology.com",
          //   ToEmail: "debojyoti.js@gmail.com",
          FromEmail: "cholder@concordebattery.com",
          EmailSubject:
            "Support requested | Concorde Rotorcraft IA Renewal 2023",
        });
      } catch (error) {}
      setIsModalVisible(false);
      Swal.fire(
        "Email sent to support!",
        "We will get back to you shortly",
        "success"
      );
    }
  };
  useEffect(() => {
    if (!isModalVisible) {
      setIsSubmitting(false);
      setName("");
      setEmail("");
      setMessage("");
    }
  }, [isModalVisible]);
  return (
    <>
      <div className="supportBtnWrapper">
        <div className="supportText">
          If you are having trouble with signing in, click on button to contact
          support
        </div>
        <button onClick={(e) => setIsModalVisible(true)}>
          Contact Support
        </button>
      </div>
      <Modal
        visible={isModalVisible}
        width="550"
        height="450"
        effect="fadeInUp"
        onClickAway={(e) => setIsModalVisible(false)}
      >
        <div className="container-fluid supportModalWrapper">
          <div
            className="dismissSupportModal"
            onClick={(e) => setIsModalVisible(false)}
          >
            x
          </div>
          <form className="pt-3" onSubmit={(e) => _validateAndSubmit(e)}>
            <div className="row">
              <h2 className="contactSupportModalHeader">Contact support</h2>
              <div className="col-lg-12 form-group">
                <input
                  type="text"
                  className="form-control form-control-lg"
                  placeholder="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <div className="field-error-wrapper">
                  {isSubmitting && !name?.length
                    ? "Please provide your name"
                    : null}
                </div>
              </div>
              <div className="col-lg-12 form-group">
                <input
                  type="text"
                  className="form-control form-control-lg"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <div className="field-error-wrapper">
                  {isSubmitting && !email?.length
                    ? "Please provide your email"
                    : null}
                </div>
              </div>
              <div className="col-lg-12 form-group">
                <textarea
                  type="text"
                  className="form-control form-control-lg"
                  placeholder="Message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
                <div className="field-error-wrapper">
                  {isSubmitting && !message?.length
                    ? "Please enter message"
                    : null}
                </div>
              </div>
              <div className="mt-3">
                <button
                  className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
};

export default SupportForm;
