import React from "react";
import { EventEmitter } from "../../utils/event-emitter";
import "./featured-sponsor-viewer.scss";

const FeaturedSponsorViewer = ({ event }) => {
  const _determineFeaturedSponsor = () => {
    const featuredSponsor = event.sponsors.find(
      (sponsor) => sponsor.id === event.featuredSponsorId
    );
    return featuredSponsor;
  };

  const featuredSponsor = _determineFeaturedSponsor();

  const _showSponsorDetails = () => {
    EventEmitter.dispatch("show-sponsor-details", featuredSponsor);
  };

  if (featuredSponsor) {
    return (
      <div className="featuredSponsorViewer" onClick={_showSponsorDetails}>
        <div className="featuredSponsorLabel">
        Featured sponsor
        </div>
        <div className="sponsorWrapper">
          {featuredSponsor?.logo?.length ? (
            <img src={featuredSponsor?.logo} alt="" />
          ) : (
            <div className="featuredSponsorName">{featuredSponsor?.name}</div>
          )}
        </div>
      </div>
    );
  } else {
    return <></>;
  }
};

export default FeaturedSponsorViewer;
