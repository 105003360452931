/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import ProtectedRoute from "../components/protected-routes";
import { logout } from "../helper-methods";
import LibraryScripts from "../library-scripts";
import { hideLoader, showLoader } from "../redux/actions/loader-data";
import ProfilePage from "./profile-page/profile-page";
import RegisteredEventsPage from "./registered-events-page/registered-events-page";
import UpcomingEventsPage from "./upcoming-events-page/upcoming-events-page";
import SpeakerDetailsModal from "../components/speaker-details-modal/speaker-details-modal";
import { clearUserData } from "../redux/actions/user-data";
import userInfoSetPage from "./user-info-set-page/user-info-set-page";
import SponsorDetailsModal from "../components/sponsor-details-modal/sponsor-details-modal";
import UpcomingEventsVideoPage from "./upcoming-events-video-page/upcoming-events-video-page";

class DashboardLayout extends Component {
  state = {};

  componentDidMount() {
    this._checkIfNormalUser();
    this._loadLibraryScripts();
  }

  _checkIfNormalUser = () => {
    if (this.props.userData.isAdmin) {
      this.props.clearUserData();
      this.props.history.replace("/login");
    }
  };

  _loadLibraryScripts = () => {
    LibraryScripts.runLibraryScripts();
  };

  getCurrentGreet = () => {
    const today = new Date();
    const curHr = today.getHours();
    if (curHr > 4 && curHr < 12) {
      return "Good Morning";
    } else if (curHr >= 12 && curHr < 18) {
      return "Good Afternoon";
    } else if (curHr >= 18 && curHr < 20) {
      return "Good Evening";
    } else {
      return "Hello";
    }
  };

  _getSidebarClass = (path) => {
    if (window.location.pathname.indexOf(path) > -1) {
      return "nav-item active";
    } else {
      return "nav-item";
    }
  };

  _getCollapseClass = (path) => {
    if (window.location.pathname.indexOf(path) > -1) {
      return "collapse show";
    } else {
      return "collapse";
    }
  };

  _loadEvent = (e, index) => {
    e.preventDefault();
    this.props.history.push(`/private/upcoming-events?event=${index}`);
  };

  _navigateTo = (path) => {
    this.props.history.push(path);
  };

  render() {
    const { userData } = this.props;

    return (
      <React.Fragment>
        <div className="container-scroller">
          {/* partial:partials/_navbar.html */}
          <nav className="navbar default-layout col-lg-12 col-12 p-0 fixed-top d-flex align-items-top flex-row">
            <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-start">
              <div className="me-3" id="toggleBtn">
                <button
                  className="navbar-toggler navbar-toggler align-self-center"
                  type="button"
                  data-bs-toggle="minimize"
                >
                  <span className="icon-menu" />
                </button>
              </div>
              <div>
                <a className="navbar-brand brand-logo" href="index.html">
                  <img
                    src={require("../assets/images/concorde-logo.png")}
                    alt="logo"
                  />
                </a>
                <a
                  className="navbar-brand brand-logo-mini"
                  href="index.html"
                ></a>
              </div>
            </div>
            <div className="navbar-menu-wrapper d-flex align-items-top">
              <ul className="navbar-nav">
                <li className="nav-item font-weight-semibold d-none d-lg-block ms-0">
                  <h1 className="welcome-text">
                    {this.getCurrentGreet()},{" "}
                    <span className="text-black fw-bold">
                      {userData?.first_name}
                    </span>
                  </h1>
                  <h3 className="welcome-sub-text">
                    Check out for new events!
                  </h3>
                </li>
              </ul>
              <ul className="navbar-nav ms-auto">
                <li className="nav-item dropdown d-none d-lg-block user-dropdown">
                  <a
                    className="nav-link"
                    id="UserDropdown"
                    href="#"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      className="img-xs rounded-circle"
                      src="https://image.flaticon.com/icons/png/512/149/149071.png"
                      alt="Profile image"
                    />{" "}
                  </a>
                  <div
                    className="dropdown-menu dropdown-menu-right navbar-dropdown"
                    aria-labelledby="UserDropdown"
                  >
                    <div className="dropdown-header text-center">
                      <img
                        className="img-md rounded-circle profile-image"
                        src="https://image.flaticon.com/icons/png/512/149/149071.png"
                        alt="Profile image"
                      />
                      <p className="mb-1 mt-3 font-weight-semibold">
                        {userData?.first_name} {userData?.last_name}
                      </p>
                      <p className="fw-light text-muted mb-0">
                        {userData?.email}
                      </p>
                    </div>
                    <a
                      className="dropdown-item"
                      href="#"
                      onClick={(e) => this._navigateTo("/private/profile")}
                    >
                      <i className="dropdown-item-icon mdi mdi-account-outline text-primary me-2" />{" "}
                      My Profile{" "}
                      <span className="badge badge-pill badge-danger">1</span>
                    </a>
                    <a
                      className="dropdown-item"
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        if (window.confirm("Are you sure want to logout?")) {
                          logout(this.props);
                        }
                      }}
                    >
                      <i className="dropdown-item-icon mdi mdi-power text-primary me-2" />
                      Sign Out
                    </a>
                  </div>
                </li>
              </ul>
              <button
                className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
                type="button"
                data-bs-toggle="offcanvas"
              >
                <span className="mdi mdi-menu" />
              </button>
            </div>
          </nav>
          {/* partial */}
          <div className="container-fluid page-body-wrapper">
            {/* partial:partials/_settings-panel.html */}
            {/* partial */}
            {/* partial:partials/_sidebar.html */}
            <nav className="sidebar sidebar-offcanvas" id="sidebar">
              <ul className="nav">
                <li className="nav-item nav-category">Events</li>
                <li
                  className={this._getSidebarClass("/private/upcoming-events")}
                >
                  <a
                    className="nav-link"
                    data-bs-toggle="collapse"
                    href="#ui-basic"
                    aria-expanded="false"
                    aria-controls="ui-basic"
                  >
                    <i className="menu-icon mdi mdi-card-text-outline" />
                    <span className="menu-title">Upcoming Events</span>
                    <i className="menu-arrow" />
                  </a>
                  <div
                    className={this._getCollapseClass(
                      "/private/upcoming-events"
                    )}
                    id="ui-basic"
                  >
                    <ul className="nav flex-column sub-menu">
                      <li className="nav-item">
                        {" "}
                        <a
                          className="nav-link"
                          href="#"
                          onClick={(e) => this._loadEvent(e, 0)}
                        >
                          PISTON IA RENEWAL SERIES
                        </a>
                      </li>
                      <li className="nav-item">
                        {" "}
                        <a
                          className="nav-link"
                          href="#"
                          onClick={(e) => this._loadEvent(e, 1)}
                        >
                          TURBINE IA RENEWAL SERIES
                        </a>
                      </li>
                      <li className="nav-item">
                        {" "}
                        <a
                          className="nav-link"
                          href="#"
                          onClick={(e) => this._loadEvent(e, 2)}
                        >
                          ROTORCRAFT IA RENEWAL SERIES
                        </a>
                      </li>
                      <li className="nav-item">
                        {" "}
                        <a
                          className="nav-link"
                          href="#"
                          onClick={(e) => this._loadEvent(e, 3)}
                        >
                          SOUTH CAROLINA
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>

                {/* <li
                  className={this._getSidebarClass("/private/upcoming-events")}
                  onClick={(e) => this._navigateTo("/private/upcoming-events")}
                >
                  <a className="nav-link" href="#">
                    <i className="menu-icon mdi mdi-card-text-outline" />
                    <span className="menu-title">Upcoming Events</span>
                  </a>
                </li> */}
                <li
                  className={this._getSidebarClass(
                    "/private/registered-events"
                  )}
                  onClick={(e) =>
                    this._navigateTo("/private/registered-events")
                  }
                >
                  <a className="nav-link" href="#">
                    <i className="menu-icon mdi mdi-table" />
                    <span className="menu-title">Registered Events</span>
                  </a>
                </li>
                <li
                  className={this._getSidebarClass(
                    "/private/upcoming-event-videos"
                  )}
                  onClick={(e) =>
                    this._navigateTo("/private/upcoming-event-videos")
                  }
                >
                  <a className="nav-link" href="#">
                    <i className="menu-icon mdi mdi-youtube" />
                    <span className="menu-title">Event Videos</span>
                  </a>
                </li>
                <li className="nav-item nav-category">Account</li>
                <li
                  className={this._getSidebarClass("/private/profile")}
                  onClick={(e) => this._navigateTo("/private/profile")}
                >
                  <a className="nav-link" href="#">
                    <i className="menu-icon mdi mdi-account-circle-outline" />

                    <span className="menu-title">Profile</span>
                  </a>
                </li>
              </ul>
            </nav>
            {/* partial */}
            <div className="main-panel">
              <Switch>
                <ProtectedRoute
                  path="/private/upcoming-events"
                  component={UpcomingEventsPage}
                  redirectTo="/login"
                />
                <ProtectedRoute
                  path="/private/upcoming-event-videos"
                  component={UpcomingEventsVideoPage}
                  redirectTo="/login"
                />
                <ProtectedRoute
                  path="/private/registered-events"
                  component={RegisteredEventsPage}
                  redirectTo="/login"
                />

                <ProtectedRoute
                  path="/private/registered-events"
                  component={RegisteredEventsPage}
                  redirectTo="/login"
                />
                <ProtectedRoute
                  path="/private/profile"
                  component={ProfilePage}
                  redirectTo="/login"
                />
                <Route
                  exact
                  path="*"
                  render={() => <Redirect to="/private/upcoming-events" />}
                />
              </Switch>
              {/* <footer className="footer">
            <div className="d-sm-flex justify-content-center justify-content-sm-between">
              <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">
                Premium{" "}
                <a href="https://www.bootstrapdash.com/" target="_blank">
                  Bootstrap admin template
                </a>{" "}
                from BootstrapDash.
              </span>
              <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">
                Copyright © 2021. All rights reserved.
              </span>
            </div>
          </footer> */}
            </div>

            {/* main-panel ends */}
          </div>
          {/* page-body-wrapper ends */}
        </div>
        <SpeakerDetailsModal />
        <SponsorDetailsModal />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (text) => dispatch(showLoader(text)),
    hideLoader: () => dispatch(hideLoader()),
    clearUserData: () => dispatch(clearUserData()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardLayout);
