import React, { useState } from "react";
import {
  Button,
  DropdownMenu,
  DropdownToggle,
  Input,
  InputGroup,
  InputGroupButtonDropdown,
} from "reactstrap";
import { COUNTRIES } from "../../config/country-codes/index";
import { deepClone } from "../../helper-methods";
import styles from "./phone-number-input.module.scss";

const PhoneNumberInput = (props) => {
  const [splitButtonOpen, setSplitButtonOpen] = useState(false);
  const [searchedText, updateSearchedText] = useState("");
  let allCountries = deepClone(COUNTRIES);

  // Check if any country code is passed from props
  const { countryCode = "+380" } = props;
  const selectedCountryIndex = allCountries.findIndex(
    (country) => country.dial_code === countryCode
  );
  const selectedCountry = deepClone(allCountries[selectedCountryIndex]);
  // Remove that country from list
  allCountries.splice(selectedCountryIndex, 1);
  // Filter
  if (searchedText.trim().length) {
    const loweredSearchText = searchedText.trim().toLowerCase();
    allCountries = allCountries.filter((country) => {
      if (country.name.toLowerCase().indexOf(loweredSearchText) > -1) {
        return true;
      }
      if (country.dial_code.toLowerCase().indexOf(loweredSearchText) > -1) {
        return true;
      }
      if (country.code.toLowerCase().indexOf(loweredSearchText) > -1) {
        return true;
      }
    });
  }
  // Add that in the begining
  allCountries = [selectedCountry].concat(allCountries);

  const { updateCountryCode, phone, updatePhoneNumber, onBlur } = props;
  const toggleSplit = () => {
    if (splitButtonOpen) {
      // Reset search text
      updateSearchedText("");
    }
    setSplitButtonOpen(!splitButtonOpen);
  };
  return (
    <div className={styles.phoneNumberWrapper1 + " codeSelector"}>
      <InputGroup>
        <InputGroupButtonDropdown
          addonType="prepend"
          isOpen={splitButtonOpen}
          toggle={toggleSplit}
        >
          <Button
            outline
            className={styles.codeButton}
            onClick={(e) => {
              e.preventDefault();
              toggleSplit();
            }}
          >
            {countryCode}
          </Button>
          <DropdownToggle split outline className={styles.dropDownTrigger} />
          <DropdownMenu style={{ left: "-43px" }}>
            <div className={styles.flagsViewerSearch}>
              <input
                placeholder="Type country name to search "
                autoFocus
                value={searchedText}
                onChange={(e) => updateSearchedText(e.target.value)}
              />
            </div>
            <div className={styles.flagsViewerWrapper}>
              {allCountries.map((country, countryIndex) => (
                <div
                  className={
                    styles.flagViewerWrapper +
                    (countryIndex === 0 ? " " + styles.selected : "")
                  }
                  key={countryIndex}
                  onClick={(e) => {
                    toggleSplit();
                    updateCountryCode(country);
                  }}
                >
                  <img src={country.getFlagImage()} alt="alt" />
                  <div>
                    {country.name} ({country.dial_code})
                  </div>
                </div>
              ))}
            </div>
          </DropdownMenu>
        </InputGroupButtonDropdown>
        <Input
          placeholder="123 456 789"
          className={styles.input}
          value={phone}
          onChange={(e) => updatePhoneNumber(e.target.value)}
          onBlur={onBlur}
        />
      </InputGroup>
    </div>
  );
};

export default PhoneNumberInput;
