import React, { Component } from "react";
import { Provider } from "react-redux";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import {
  ToastsContainer,
  ToastsContainerPosition,
  ToastsStore,
} from "react-toasts";
import { PersistGate } from "redux-persist/integration/react";
import "./assets/login.css";
import ProtectedRoute from "./components/protected-routes";
import FullPageLoader from "./containers/full-page-loader";
import DashboardLayout from "./pages/dashboard-layout";
import ForgotPassword from "./pages/forgot-password/forgot-password";
import LoginPage from "./pages/login-page/login-page";
import RegisterPage from "./pages/register-page/register-page";
import { persistor, store } from "./redux/store";
import "./App.scss";
import FirebaseAuthService from "./firebase-auth-service";
import PublicRoute from "./components/public-route";
import AdminModule from "./admin-module/index";
import EmailLinkSentSuccessPage from "./pages/email-link-sent-success-page/email-link-sent-success-page";
import RedirectLinkHandlePage from "./pages/redirect-link-handle-page/redirect-link-handle-page";
import userInfoSetPage from "./pages/user-info-set-page/user-info-set-page";
import DownloadCertPage from "./pages/download-cert-page/download-cert-page";

class App extends Component {
  state = {};

  componentDidMount() {
    FirebaseAuthService.initialize();
  }

  render() {
    return (
      <Provider store={store}>
        <PersistGate loading={<div></div>} persistor={persistor}>
          <Router>
            <div>
              <FullPageLoader></FullPageLoader>
              <ToastsContainer
                store={ToastsStore}
                position={ToastsContainerPosition.BOTTOM_RIGHT}
              />
              <Switch>
                <PublicRoute
                  path="/login"
                  component={LoginPage}
                  redirectRoute="/private"
                />
                <PublicRoute
                  path="/handle-redirect-login"
                  component={RedirectLinkHandlePage}
                  redirectRoute="/private"
                />
                <PublicRoute
                  path="/email-link-success"
                  component={EmailLinkSentSuccessPage}
                  redirectRoute="/private"
                />
                <PublicRoute
                  path="/register"
                  component={RegisterPage}
                  redirectRoute="/private"
                />
                <PublicRoute
                  path="/forgot-password"
                  component={ForgotPassword}
                  redirectRoute="/private"
                />
                <Route
                  path="/download"
                  component={DownloadCertPage}
                  redirectRoute="/private"
                />
                <Route path="/admin" component={AdminModule} />
                <ProtectedRoute
                  path="/private/complete-profile"
                  component={userInfoSetPage}
                  redirectTo="/login"
                />
                <ProtectedRoute
                  path="/private"
                  component={DashboardLayout}
                  redirectRoute="/login"
                />
                <Route
                  exact
                  path="*"
                  render={() => <Redirect to="/private" />}
                />
              </Switch>
            </div>
          </Router>
        </PersistGate>
      </Provider>
    );
  }
}

export default App;
