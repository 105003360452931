import React, { useState, useEffect } from "react";
import "./manage-events-page.scss";
import EventEditorPopup from "../../components/event-editor-popup/event-editor-popup";

import { store } from "../../../redux/store";
import { showLoader, hideLoader } from "../../../redux/actions/loader-data";
import ManageEventHelper from "../../helpers/manage-events-helper";

const ManageEventsPage = () => {
  const [events, setEvents] = useState([]);
  const [editorOptions, setEditorOptions] = useState({
    isEditing: false,
    event: null,
    isActive: false,
  });

  const fetchAllEvents = async () => {
    store.dispatch(showLoader("Loading events..."));
    try {
      const { data } = await ManageEventHelper.getAllEventsWithSlots();
      const formattedEvents = ManageEventHelper.categorisedEvents(data);
      setEvents(formattedEvents);
    } catch (error) {
      console.error("Failed to fetch events:", error);
    }
    store.dispatch(hideLoader("Loading events..."));
  };

  useEffect(() => {
    fetchAllEvents();
  }, []);

  const showAddEditEventModal = (event = null) => {
    setEditorOptions({
      isEditing: event ? true : false,
      event,
      isActive: true,
    });
  };

  const hideAddEditEventModal = () => {
    setEditorOptions({
      isEditing: false,
      event: null,
      isActive: false,
    });
  };

  const onSubmitEvent = async () => {
    hideAddEditEventModal();
    fetchAllEvents();
  };

  return (
    <>
      <div className="manageEventSponsorsPageWrapper">
        <div>
          <h1>Manage Events</h1>

          {events.map((yearGroup, yearGroupIndex) => (
            <div key={yearGroup.year} className="year-section mt-5 pt-5">
              <h2 className="d-flex align-items-center justify-content-between">
                <span>
                {parseInt(yearGroup.year) - 1} - {yearGroup.year} events
                </span>
                {yearGroupIndex === 0 && (
                  <button
                    className="btn btn-primary"
                    onClick={() => showAddEditEventModal()}
                  >
                    <i className="fa fa-plus"></i> <span>Add new event</span>
                  </button>
                )}
              </h2>

              <div className="row">
                {yearGroup.events.map((event) => (
                  <div className="col-md-4" key={event.event_id}>
                    <div className="eventCard">
                      <div className="eventCardInner">
                        <div className="eventDetails">
                          <div className="eventTitle">{event.event_name}</div>
                          <div className="eventDuration">
                            <i className="far fa-calendar"></i>{" "}
                            <span>{event.formattedDuration}</span>
                          </div>
                          <div className="eventSlots">
                            <i className="fas fa-border-all"></i>{" "}
                            <span>{event.slots.length} slots</span>
                          </div>
                        </div>
                      </div>

                      <div
                        className="eventCardFooter"
                        onClick={() => showAddEditEventModal(event)}
                      >
                        <i className="fa fa-pen"></i> <span>Edit event</span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}

          {editorOptions.isActive && (
            <EventEditorPopup
              isEditing={editorOptions.isEditing}
              event={editorOptions.event}
              onSubmit={onSubmitEvent}
              onCancel={hideAddEditEventModal}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ManageEventsPage;
