import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import EventDetailsPrompt from "../../components/event-details-prompt/event-details-prompt";
import EventRegisterPrompt from "../../components/event-register-prompt/event-register-prompt";
import EventViewer from "../../components/event-viewer/event-viewer";
import SpeakerDetailsModal from "../../components/speaker-details-modal/speaker-details-modal";
import { extractQueryParams } from "../../helper-methods/index";
import {
  getAllFocus,
  getAllRegisteredEvents,
  getAllUpcomingEvents,
} from "../../http-calls";
import { hideLoader, showLoader } from "../../redux/actions/loader-data";
import { EventEmitter } from "../../utils/event-emitter";
import "./upcoming-events-video-page.scss";

class UpcomingEventsPage extends Component {
  state = {
    allEvents: [],
    myEventSlots: [],
    registrationPrompt: {
      isVisible: false,
      selectedEvent: null,
    },
    detailsPrompt: {
      isVisible: false,
      selectedEvent: null,
    },
    allFocus: [],
    scrollIndex: -1,
  };

  eventRef = [null, null, null];

  componentDidMount() {
    this._loadData();
    this._registerEvents();
  }

  componentDidUpdate(prevProps) {
    const params = extractQueryParams();
    if (params.event && params.event !== this.state.scrollIndex) {
      this.setState({ scrollIndex: params.event });
      this._scrollTo(params.event);
    }
  }

  _scrollTo = (index) => {
    console.log("_scrollTo :>> ", index);
    if (this.eventRef[index]) {
      this.eventRef[index].scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  _registerEvents = () => {
    EventEmitter.subscribe("scroll-to");
  };

  _formatEvents = (sortedEvents) => {
    return sortedEvents.map((event) => {
      event.formattedSpeakers = {};
      event.speakers.forEach((speaker) => {
        if (!event.formattedSpeakers[speaker.day]) {
          event.formattedSpeakers[speaker.day] = [];
        }
        event.formattedSpeakers[speaker.day].push(speaker);
      });
      return event;
    });
  };

  _loadData = async () => {
    try {
      this.props.showLoader();
      let {
        data: { events: allEventSlots },
      } = await getAllUpcomingEvents();
      let {
        data: { registeredEvents: registeredEventsSlots },
      } = await getAllRegisteredEvents();
      const {
        data: { focus: allFocus },
      } = await getAllFocus();
      const allEvents = {};
      const currentYear = moment().format("YYYY") - 1;
      console.log("allEventSlots 1111111111111111113:>> ", allEventSlots);
      // allEventSlots = allEventSlots.filter((event) => event.event_name.indexOf('Carolina') === -1);
      console.log(
        "registeredEventsSlots 1111111111111111113:>> ",
        registeredEventsSlots
      );
      allEventSlots = allEventSlots.filter(
        (event) => event.start_date.indexOf(parseInt(currentYear) + 1) > -1
      );
      registeredEventsSlots = registeredEventsSlots.filter(
        (event) => event?.start_date?.indexOf(parseInt(currentYear) + 1) > -1
      );
      allEventSlots.forEach((eventSlot) => {
        if (
          !allEvents[
            `${eventSlot.event_id}+${eventSlot.start_date}+${eventSlot.end_date}`
          ]
        ) {
          allEvents[
            `${eventSlot.event_id}+${eventSlot.start_date}+${eventSlot.end_date}`
          ] = {
            eventName: eventSlot.event_name,
            speakers: eventSlot.speakers,
            description: eventSlot.description,
            featuredSponsorId: eventSlot.featured_sponsor_id,
            eventId: eventSlot.event_id,
            isEnabled: eventSlot.isEnabled === 1,
            startDate: eventSlot.start_date,
            endDate: eventSlot.end_date,
            focus: allFocus.find((f) => f.focus_id === eventSlot.focus_id)[
              "focus_name"
            ],
            focusId: eventSlot.focus_id,
            slots: [],
            sponsors: eventSlot.sponsors,
          };
        }
        const slot = {
          startTime: eventSlot.start_time,
          endTime: eventSlot.end_time,
          slotId: eventSlot.event_slot_id,
          alreadyRegistered: false,
        };
        const isAlreadyRegistered = registeredEventsSlots.find(
          (s) => s.event_slot_id === eventSlot.event_slot_id
        );
        slot.alreadyRegistered = !!isAlreadyRegistered;
        allEvents[
          `${eventSlot.event_id}+${eventSlot.start_date}+${eventSlot.end_date}`
        ].slots.push(slot);
      });
      console.log("allEvents :>> ", allEvents);
      const sortedEvents = this._sortEvents(Object.values(allEvents));
      console.log("sortedEvents", sortedEvents);
      const formattedEvents = this._formatEvents(sortedEvents);
      this.setState({ allEvents: formattedEvents, allFocus }, () => {
        if (this.state.scrollIndex > -1) {
          this._scrollTo(this.state.scrollIndex);
        }
      });
    } catch (error) {
      console.log("error :>> ", error);
    }
    this.props.hideLoader();
  };

  _sortEvents = (allEvents) => {
    return allEvents.sort(
      (e1, e2) =>
        moment(e1.startDate, "DD/MM/YYYY").toDate() -
        moment(e2.startDate, "DD/MM/YYYY").toDate()
    );
  };

  _showRegistrationPrompt = (event) => {
    this.setState({
      registrationPrompt: {
        isVisible: true,
        selectedEvent: event,
      },
    });
  };

  _hideRegistrationPrompt = () => {
    this.setState({
      registrationPrompt: {
        isVisible: false,
        selectedEvent: null,
      },
    });
  };

  _showDetailsPrompt = (event) => {
    this.setState({
      detailsPrompt: {
        isVisible: true,
        selectedEvent: event,
      },
    });
  };

  _hideDetailsPrompt = () => {
    this.setState({
      detailsPrompt: {
        isVisible: false,
        selectedEvent: null,
      },
    });
  };

  _onRegistrationSuccess = () => {
    this._hideRegistrationPrompt();
    this._loadData();
  };

  render() {
    const { allEvents, registrationPrompt, allFocus, detailsPrompt } =
      this.state;

    console.log("allEvents :>> ", allEvents);

    return (
      <div className="content-wrapper page-outer-wrapper">
        <div className="row">
          <div className="col-sm-12">
            <div className="cardsWrapper">
              {allEvents.map((event, eventIndex) => (
                <div
                  key={event.eventId}
                  ref={(ref) => (this.eventRef[eventIndex] = ref)}
                  className="eventOuterWrapper"
                >
                  <EventViewer
                    event={event}
                    onSuccess={this._loadData}
                    onlyVideos={true}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        <EventRegisterPrompt
          isVisible={registrationPrompt.isVisible}
          event={registrationPrompt.selectedEvent}
          onDimiss={this._hideRegistrationPrompt}
          onSuccess={this._onRegistrationSuccess}
          allFocus={allFocus}
        />
        <EventDetailsPrompt
          isVisible={detailsPrompt.isVisible}
          event={detailsPrompt.selectedEvent}
          onDimiss={this._hideDetailsPrompt}
          onRegister={(e) => {
            this._showRegistrationPrompt(detailsPrompt.selectedEvent);
            this._hideDetailsPrompt();
          }}
          allFocus={allFocus}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (text) => dispatch(showLoader(text)),
    hideLoader: () => dispatch(hideLoader()),
  };
};

export default connect(null, mapDispatchToProps)(UpcomingEventsPage);
