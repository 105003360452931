import React, { useCallback, useEffect, useState } from "react";
import { RotatingLines } from "react-loader-spinner";
import OutsideClickHandler from "react-outside-click-handler";
import { getAllUpcomingEvents, updateUserByAdmin } from "../../../http-calls";
import UserForm from "../user-form/user-form";
import "./user-editor-sidedock.scss";

const UserEditorSidedock = ({
  isVisible,
  selectedRegistration,
  onDismiss = () => {},
  onUserUpdate = () => {},
}) => {
  console.log("selectedRegistration 2251:>> ", selectedRegistration);
  const [isLoaderActive, setIsLoaderActive] = useState(false);
  const [allEvents, setAllEvents] = useState([]);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState();

  const _fetchAllEvents = async () => {
    const {
      data: { events },
    } = await getAllUpcomingEvents();
    // console.log('data', data)
    setAllEvents(events);
  };

  const _updateSelectedTimeSlot = useCallback(() => {
    setSelectedTimeSlot(selectedRegistration.eventSlotId);
  }, [selectedRegistration]);

  const convert24To12 = (time24) => {
    var ts = time24;
    var H = +ts.substr(0, 2);
    var h = H % 12 || 12;
    h = h < 10 ? "0" + h : h; // leading 0 at the left for 1 digit hours
    var ampm = H < 12 ? " AM" : " PM";
    ts = h + ts.substr(2, 3) + ampm;
    return ts;
  };

  const _prepareRegistrationParams = (fields) => {
    return {
      "User Name": `${fields.firstName} ${fields.lastName}`,
      "User State": fields.state,
      "User Zip": fields.zip,
      "User Country": fields.country,
      "User Phone": fields.phone,
      "User Company Name": fields.companyName,
    };
  };

  const _saveChanges = async (fields) => {
    console.log("fields :>> ", fields);
    setIsLoaderActive(true);
    const registrationParams = _prepareRegistrationParams(fields);
    try {
      await updateUserByAdmin({
        fields,
        userId: selectedRegistration.userId,
      });
      onUserUpdate({
        ...selectedRegistration,
        ...registrationParams,
      });
    } catch (error) {
      console.log("error :>> ", error);
    }
    setIsLoaderActive(false);
  };

  const _tryToDismiss = () => {
    if (!isLoaderActive) {
      onDismiss();
    } else {
      // alert('Please wai')
    }
  };

  useEffect(() => {
    _fetchAllEvents();
  }, []);

  useEffect(() => {
    if (selectedRegistration) {
      setIsLoaderActive(false);
      _updateSelectedTimeSlot();
    }
  }, [_updateSelectedTimeSlot, selectedRegistration]);

  return (
    <>
      <div className={`userSideBarOuterWrapper ${isVisible ? "show" : ""}`}>
        <OutsideClickHandler onOutsideClick={_tryToDismiss}>
          <div className={`userSideBarWrapper ${isVisible ? "show" : ""}`}>
            {isLoaderActive ? (
              <div className="loaderWrapper">
                <RotatingLines
                  strokeColor="grey"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="96"
                  visible={true}
                />
              </div>
            ) : (
              <>
                {selectedRegistration ? (
                  <UserForm
                    user={selectedRegistration}
                    onSubmit={_saveChanges}
                    onCancel={onDismiss}
                  />
                ) : (
                  <></>
                )}
              </>
            )}
          </div>
        </OutsideClickHandler>
      </div>
    </>
  );
};

export default UserEditorSidedock;
