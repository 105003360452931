import React, { useState } from "react";
import {
  DropdownMenu,
  DropdownToggle,
  InputGroup,
  InputGroupButtonDropdown,
} from "reactstrap";
import { COUNTRIES } from "../../config/country-codes/index";
import { deepClone } from "../../helper-methods";
import styles from "./country-selector-input.module.scss";

const CountrySelector = (props) => {
  const [splitButtonOpen, setSplitButtonOpen] = useState(false);
  const [searchedText, updateSearchedText] = useState("");
  let allCountries = deepClone(COUNTRIES);

  // Check if any country code is passed from props
  const { countryCode = "" } = props;
  let selectedCountry = null;
  if (countryCode && countryCode.length) {
    const selectedCountryIndex = allCountries.findIndex(
      (country) => country.name === countryCode
    );
    selectedCountry = deepClone(allCountries[selectedCountryIndex]);
    // Remove that country from list
    // allCountries.splice(selectedCountryIndex, 1);
  }
  // Filter
  if (searchedText.trim().length) {
    const loweredSearchText = searchedText.trim().toLowerCase();
    allCountries = allCountries.filter((country) => {
      if (country.name.toLowerCase().indexOf(loweredSearchText) > -1) {
        return true;
      }
      if (country.dial_code.toLowerCase().indexOf(loweredSearchText) > -1) {
        return true;
      }
      if (country.code.toLowerCase().indexOf(loweredSearchText) > -1) {
        return true;
      }
    });
  }
  // Add that in the begining
  // if (selectedCountry) {
  //   allCountries = [selectedCountry].concat(allCountries);
  // }

  const { updateCountryCode, phone, updatePhoneNumber, onBlur } = props;
  const toggleSplit = () => {
    if (splitButtonOpen) {
      // Reset search text
      updateSearchedText("");
    }
    setSplitButtonOpen(!splitButtonOpen);
  };
  const currentCountry = allCountries.find((c) => c.name === countryCode);
  return (
    <div className={styles.phoneNumberWrapper + ' phoneSelector'}>
      <InputGroup>
        <InputGroupButtonDropdown
          addonType="prepend"
          isOpen={splitButtonOpen}
          toggle={toggleSplit}
        >
          <DropdownToggle caret className="countryLabel">
            <div className="countrySelectionLabel">
              {currentCountry ? (
                <>
                  <img src={currentCountry.getFlagImage()} alt="icon" />
                  <div>{currentCountry.name}</div>
                </>
              ) : (
                <div className="noCountrySelected">Country</div>
              )}
            </div>
          </DropdownToggle>
          <DropdownToggle split outline className={styles.dropDownTrigger} />
          <DropdownMenu style={{ lefy: "-43px" }}>
            <div className={styles.flagsViewerSearch}>
              <input
                placeholder="Type country name or code to search "
                autoFocus
                value={searchedText}
                onChange={(e) => updateSearchedText(e.target.value)}
              />
            </div>
            <div className={styles.flagsViewerWrapper}>
              {allCountries.map((country, countryIndex) => (
                <div
                  className={
                    styles.flagViewerWrapper +
                    (countryIndex === 0 ? " " + styles.selected : "")
                  }
                  key={countryIndex}
                  onClick={(e) => {
                    toggleSplit();
                    updateCountryCode(country.name);
                  }}
                >
                  <img src={country.getFlagImage()} alt="icon" />
                  <div>
                    {country.name} ({country.dial_code})
                  </div>
                </div>
              ))}
            </div>
          </DropdownMenu>
        </InputGroupButtonDropdown>
      </InputGroup>
    </div>
  );
};

export default CountrySelector;
