import React from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import FirebaseAuthService from "../../firebase-auth-service";
import { createUser, tokenExchange } from "../../http-calls";
import { showLoader } from "../../redux/actions/loader-data";
import { updateUserData } from "../../redux/actions/user-data";
import { store } from "../../redux/store";
import "./redirect-link-handle-page.scss";

const RedirectLinkHandlePage = () => {
  const history = useHistory();

  const _processLogin = async () => {
    // this.props.showLoader("Logging you in");
    store.dispatch(showLoader("Logging you in"));
    const email = window.localStorage.getItem("emailForSignIn");
    const firebaseClientToken = await FirebaseAuthService.getTokenFromLoginLink(
      window.location.href
    );
    if (firebaseClientToken?.length) {
      try {
        const { data: userData } = await tokenExchange({
          authToken: firebaseClientToken,
        });
        store.dispatch(
          updateUserData({
            ...userData.userData,
            token: userData.token,
          })
        );
        // this.props.hideLoader();
        // Success login
      } catch (error) {
        const { data: userData } = await createUser({
          authToken: firebaseClientToken,
          firstName: "",
          lastName: "",
          state: "",
          zip: "",
          country: "",
          phone: "",
          companyName: "",
          email: email,
        });
        store.dispatch(
          updateUserData({
            ...userData.userData,
            token: userData.token,
          })
        );
      }
      history.replace("/private/complete-profile");
    } else {
      history.replace("/login");
    }
  };

  useEffect(() => {
    _processLogin();
  }, []);

  return <>Logging you in</>;
};

export default RedirectLinkHandlePage;
