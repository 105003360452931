/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import { showLoader, hideLoader } from "../../redux/actions/loader-data";
import { updateUserData } from "../../redux/actions/user-data";
// import { login } from "../../http-calls";
import { showToast } from "../../helper-methods";
import "./login-page.scss";
import FirebaseAuthService from "../../firebase-auth-service";
import { tokenExchange } from "../../http-calls";
import Swal from "sweetalert2";
import SupportForm from "../../components/support-form/support-form";

class LoginPage extends Component {
  state = {
    formFields: {
      email: {
        value: "",
        isValid: false,
        isDirty: false,
      },
    },
    isFormValid: false,
    redirectTo: null,
  };

  componentDidMount() {
    if (this.props.userData.token) {
      // Already logged in
      this.props.history.replace("/");
    }
    this.props.showLoader("Loading");
    setTimeout(() => {
      FirebaseAuthService.clearLoginEmail();
      this.props.hideLoader();
    }, 1000);
  }

  _storeRedirectParamsIfAvailable = () => {
    const { location } = this.props;
    if (location.extras) {
      this.setState(
        {
          redirectTo: location.extras.pathname + location.extras.search,
        },
        () => {
          if (
            location.extras.pathname !== "" &&
            location.extras.pathname !== "/"
          ) {
            showToast("Please login first to continue");
          }
        }
      );
    }
  };

  _markAsDirty = (fieldName) => {
    const { formFields } = this.state;
    formFields[fieldName].isDirty = true;
    this.setState({ formFields });
    this._validateForm();
  };

  _updateFieldValue = (fieldName, value) => {
    const { formFields } = this.state;
    formFields[fieldName].value = value;
    this.setState({ formFields });
    if (formFields[fieldName].isDirty) {
      // Validate
      this._validateForm();
    }
  };

  _validateForm = () => {
    const { formFields } = this.state;
    let isFormValid = true;
    Object.keys(formFields).forEach((fieldName, index) => {
      switch (fieldName) {
        case "email": {
          var emailRegex =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          if (emailRegex.test(String(formFields.email.value).toLowerCase())) {
            formFields.email.isValid = true;
          } else {
            formFields.email.isValid = false;
            isFormValid = false;
          }
          break;
        }
        default: {
        }
      }
    });
    this.setState({ formFields, isFormValid });
  };

  _makeAllFieldDirty = () => {
    const { formFields } = this.state;
    Object.keys(formFields).forEach((fieldName, index) => {
      formFields[fieldName].isDirty = true;
    });
  };

  _validateAndSubmit = async (e) => {
    e.preventDefault();
    const { formFields, isFormValid, redirectTo } = this.state;
    this._makeAllFieldDirty();
    this._validateForm();
    console.log("log");
    if (isFormValid) {
      try {
        console.log("2");
        FirebaseAuthService.generateLoginLink(formFields.email.value);

        // this.props.showLoader("Logging you in");
        // await FirebaseAuthService.login(
        //   formFields.email.value,
        //   formFields.password.value
        // );
        // const firebaseClientToken =
        //   await FirebaseAuthService.getFirebaseClientToken();
        // const { data: userData } = await tokenExchange({
        //   authToken: firebaseClientToken,
        // });
        // console.log("userData :>> ", userData);
        // // Login success
        // this.props.updateUserData({
        //   ...userData.userData,
        //   token: userData.token,
        // });
        // this.props.hideLoader();
        // // Success login
        // this.props.history.replace("/email-link-success");
        Swal.fire(
          "Login link sent!",
          "Please check your mail inbox (including spam) to proceed further",
          "success"
        );
        formFields.email.value = "";
        this.setState({ formFields });
      } catch (loginError) {
        console.log("loginError :>> ", loginError);
        this.props.hideLoader();
        showToast(
          loginError.message ? loginError.message : "Password missmatch",
          "error"
        );
      }
    }
  };

  render() {
    const { formFields } = this.state;
    return (
      <React.Fragment>
        <div className="container-scroller">
          <div className="container-fluid page-body-wrapper full-page-wrapper login-page-wrapper">
            <div className="content-wrapper d-flex align-items-center auth px-0">
              <div className="row w-100 mx-0">
                <div className="col-lg-4 mx-auto">
                  <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                    <div className=" mt-1 fw-light mb-4">
                      Don't have an account?{" "}
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          this.props.history.push("register");
                        }}
                        className="text-primary"
                      >
                        Create
                      </a>{" "}
                      before login
                    </div>
                    <div className="brand-logo mt-4">
                      <img
                        src={require("../../assets/images/concorde-logo.png")}
                        alt="logo"
                      />
                    </div>
                    <h4 className="mt-4">Login</h4>
                    <h6 className="fw-light">Sign in to continue.</h6>
                    <form
                      className="pt-3"
                      onSubmit={(e) => this._validateAndSubmit(e)}
                    >
                      <div className="form-group">
                        <input
                          type="email"
                          className="form-control form-control-lg"
                          id="exampleInputEmail1"
                          placeholder="Email"
                          value={formFields.email.value}
                          onChange={(e) =>
                            this._updateFieldValue("email", e.target.value)
                          }
                          onBlur={() => this._markAsDirty("email")}
                        />
                        <div className="field-error-wrapper">
                          {formFields.email.isDirty && !formFields.email.isValid
                            ? "Invalid Email"
                            : null}
                        </div>
                      </div>
                      <div className="mt-3">
                        <button
                          className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                          type="submit"
                        >
                          Send link to email
                        </button>
                      </div>

                      <SupportForm />
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* content-wrapper ends */}
          </div>
          {/* page-body-wrapper ends */}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (text) => dispatch(showLoader(text)),
    hideLoader: () => dispatch(hideLoader()),
    updateUserData: (userData) => dispatch(updateUserData(userData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
