import React, { Component, useState } from "react";
import copy from "copy-to-clipboard";
import OutsideClickHandler from "react-outside-click-handler";

const LinkActions = ({ link, linkType }) => {
  const [isExtendedActionsVisible, setIsExtendedActionsVisible] =
    useState(false);

  const _prepareActions = () => {
    switch (linkType) {
      // eslint-disable-next-line no-lone-blocks
      case "webAddress": {
        return [
          {
            actionLabel: "Copy link to clipboard",
            clickHandler: () => {
              copy(link, {
                debug: true,
                message: "Press #{key} to copy",
              });
            },
          },
          {
            actionLabel: "Open in new tab",
            clickHandler: () => {
              // Add https if not present
              var prefix = 'https://';
              let formattedLink = link;
              if (formattedLink.substr(0, prefix.length) !== prefix)
              {
                  formattedLink = prefix + formattedLink;
              }
              window.open(formattedLink, "_blank");
            },
          },
        ];
      }
      case "email": {
        return [
          {
            actionLabel: "Copy email address to clipboard",
            clickHandler: () => {
              copy(link, {
                debug: true,
                message: "Press #{key} to copy",
              });
            },
          },
          {
            actionLabel: "Open with email application",
            clickHandler: () => {
              window.location.href = "mailto:" + link;
            },
          },
        ];
      }
      default: {
        return [];
      }
    }
  };

  const actions = _prepareActions();

  if (link?.length) {
    return (
      <OutsideClickHandler
        onOutsideClick={() => {
          setIsExtendedActionsVisible(false);
        }}
      >
        <div
          className="website"
          onClick={(e) => setIsExtendedActionsVisible(true)}
        >
          {link}
          {isExtendedActionsVisible ? (
            <div className="detailsViewer">
              {actions.map((action, actionIndex) => (
                <div
                  className="action"
                  key={actionIndex}
                  onClick={(e) => {
                    setTimeout(() => {
                      setIsExtendedActionsVisible(false)
                    }, 200);
                    action.clickHandler()
                  }}
                >
                  {action.actionLabel}
                </div>
              ))}
            </div>
          ) : (
            <></>
          )}
        </div>
      </OutsideClickHandler>
    );
  } else {
    return <></>;
  }
};

export default LinkActions;
