import React, { Component } from "react";
import { connect } from "react-redux";
import CountrySelector from "../../components/country-selector-input/country-selector-input";
import PhoneNumberInput from "../../components/phone-number-input/phone-number-input";
import StateSelector from "../../components/state-selector/state-selector";
import { COUNTRIES } from "../../config/country-codes";
import { showToast } from "../../helper-methods";
import { getProfileData, updateProfileData } from "../../http-calls";
import { hideLoader, showLoader } from "../../redux/actions/loader-data";
import { updateUserData } from "../../redux/actions/user-data";
import "./profile-page.scss";

class ProfilePage extends Component {
  state = {
    formFields: {
      firstName: {
        value: "",
        isValid: false,
        isDirty: false,
      },
      lastName: {
        value: "",
        isValid: true,
        isDirty: false,
      },
      state: {
        value: "",
        isValid: false,
        isDirty: false,
      },
      zip: {
        value: "",
        isValid: false,
        isDirty: false,
      },
      country: {
        value: "",
        isValid: false,
        isDirty: false,
      },
      phone: {
        value: "",
        isValid: false,
        isDirty: false,
      },
      companyName: {
        value: "",
        isValid: false,
        isDirty: false,
      },
    },
    isFormValid: false,
    countryCode: "+1",
    redirectTo: null,
  };

  componentDidMount() {
    this._setValues();
  }

  _onCountryCodeUpdate = (country) => {
    const { countryCode, formFields } = this.state;
    if (
      countryCode !== country.dial_code ||
      formFields.phone.value !== country.name
    ) {
      formFields.phone.value = "";
      formFields.country.value = country.name;
      this.setState({ countryCode: country.dial_code, formFields });
    }
  };

  _getCountryCodeFromCountryName = (countryName) => {
    const selectedCountry = COUNTRIES.find((c) => c.name === countryName);
    if (selectedCountry?.dial_code?.length) {
      return selectedCountry?.dial_code;
    }
  };

  _onCountryUpdate = (country) => {
    const { formFields } = this.state;
    if (formFields.country.value !== country) {
      formFields.phone.value = "";
      let countryCode = this._getCountryCodeFromCountryName(country);
      this._updateFieldValue("country", country);
      this.setState({ countryCode, formFields });
    }
  };

  _setValues = async () => {
    this.props.showLoader();
    try {
      const {
        data: { userData },
      } = await getProfileData();
      const { formFields } = this.state;
      formFields.firstName.value = userData.first_name;
      formFields.lastName.value = userData.last_name;
      formFields.state.value = userData.state;
      formFields.zip.value = userData.zip;
      formFields.country.value = userData.country || "";
      formFields.phone.value = this._getPhone(userData.phone, userData.country);
      let countryCode = this._getCountryCode(userData.phone, userData.country)
      formFields.companyName.value = userData.company_name || "";
      this.setState({ formFields, countryCode });
    } catch (error) {
      console.log("error :>> ", error);
    }
    this.props.hideLoader();
  };

  _getCountryCode = (phoneWithCountryCode, country) => {
    // Try to get the code
    const parts = phoneWithCountryCode.split("||");
    if (parts.length === 2) {
      return parts[0]
    } else {
      if (country?.length) {
        const selectedCountry = COUNTRIES.find(c => c.name === country)
        if (selectedCountry?.dial_code) {
          return selectedCountry.dial_code;
        } 
      }
    }
    return "+1";
  }

  _getPhone = (phoneWithCountryCode, country) => {
    // Try to get the code
    const parts = phoneWithCountryCode.split("||");
    if (parts.length === 2) {
      return parts[1];
    } else if (parts.length === 1) {
      return parts[0];
    }
    return "";
  }

  _markAsDirty = (fieldName) => {
    const { formFields } = this.state;
    formFields[fieldName].isDirty = true;
    this.setState({ formFields });
    this._validateForm();
  };

  _updateFieldValue = (fieldName, value) => {
    const { formFields } = this.state;
    formFields[fieldName].value = value;
    this.setState({ formFields });
    if (formFields[fieldName].isDirty) {
      // Validate
      this._validateForm();
    }
  };

  _updatePhoneFieldValue = (value) => {
    const { formFields } = this.state;
    formFields.phone.value = value.replace(/[^\d+]/g, "");
    this.setState({ formFields });
    if (formFields.phone.isDirty) {
      // Validate
      this._validateForm();
    }
  };

  _validateForm = () => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;
      let isFormValid = true;
      Object.keys(formFields).forEach((fieldName, index) => {
        switch (fieldName) {
          case "firstName": {
            if (formFields.firstName.value.length >= 1) {
              formFields.firstName.isValid = true;
            } else {
              formFields.firstName.isValid = false;
              isFormValid = false;
            }
            break;
          }
          case "lastName": {
            if (formFields.lastName.value.length >= 1) {
              formFields.lastName.isValid = true;
            } else {
              formFields.lastName.isValid = false;
              isFormValid = false;
            }
            break;
          }
          case "state": {
            if (formFields.state.value.length >= 1) {
              formFields.state.isValid = true;
            } else {
              formFields.state.isValid = false;
              isFormValid = false;
            }
            break;
          }
          case "zip": {
            if (formFields.zip.value.length >= 1) {
              formFields.zip.isValid = true;
            } else {
              formFields.zip.isValid = false;
              isFormValid = false;
            }
            break;
          }
          case "country": {
            if (formFields.country.value.length >= 1) {
              formFields.country.isValid = true;
            } else {
              formFields.country.isValid = false;
              isFormValid = false;
            }
            break;
          }
          case "phone": {
            if (formFields.phone.value.length >= 1) {
              formFields.phone.isValid = true;
            } else {
              formFields.phone.isValid = false;
              isFormValid = false;
            }
            break;
          }
          case "companyName": {
            if (formFields.companyName.value.length >= 1) {
              formFields.companyName.isValid = true;
            } else {
              formFields.companyName.isValid = false;
              isFormValid = false;
            }
            break;
          }
          default: {
          }
        }
      });
      this.setState({ formFields, isFormValid }, () => {
        resolve();
      });
    });
  };

  _makeAllFieldDirty = () => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;
      Object.keys(formFields).forEach((fieldName, index) => {
        formFields[fieldName].isDirty = true;
      });
      this.setState({ formFields }, () => {
        resolve();
      });
    });
  };

  _validateAndSubmit = async (e) => {
    e.preventDefault();
    await this._makeAllFieldDirty();
    await this._validateForm();
    const { formFields, isFormValid, countryCode } = this.state;
    if (isFormValid) {
      try {
        this.props.showLoader();
        const { data: userData } = await updateProfileData({
          firstName: formFields.firstName.value,
          lastName: formFields.lastName.value,
          state: formFields.state.value,
          zip: formFields.zip.value,
          country: formFields.country.value,
          companyName: formFields.companyName.value,
          phone: `${countryCode}||${formFields.phone.value}`,
        });

        this.props.updateUserData({
          ...this.props.userData,
          ...userData.userData,
        });
        this.props.hideLoader();
        showToast("Profile updated", "success");
      } catch (loginError) {
        this.props.hideLoader();
        showToast(
          loginError.reason ? loginError.reason : "Registration failed",
          "error"
        );
      }
    }
  };

  render() {
    const { formFields, countryCode } = this.state;

    return (
      <>
        <div className="content-wrapper profile-page-outer-wrapper">
          <div className="row">
            <div className="col-12 grid-margin">
              <div className="card">
                <div className="card-body">
                  <h4 className="card-title">Update profile</h4>
                  <form
                    className="form-sample"
                    onSubmit={(e) => this._validateAndSubmit(e)}
                  >
                    <p className="card-description">Personal info</p>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            First Name
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="First Name"
                              value={formFields.firstName.value}
                              onChange={(e) =>
                                this._updateFieldValue(
                                  "firstName",
                                  e.target.value
                                )
                              }
                              onBlur={() => this._markAsDirty("firstName")}
                            />
                            <div className="profileErrorText">
                              {formFields.firstName.isDirty &&
                              !formFields.firstName.isValid
                                ? "Cannot be empty"
                                : null}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Last Name
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Last Name"
                              value={formFields.lastName.value}
                              onChange={(e) =>
                                this._updateFieldValue(
                                  "lastName",
                                  e.target.value
                                )
                              }
                              onBlur={() => this._markAsDirty("lastName")}
                            />
                            <div className="profileErrorText">
                              {formFields.lastName.isDirty &&
                              !formFields.lastName.isValid
                                ? "Cannot be empty"
                                : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Country
                          </label>
                          <div className="col-sm-9">
                            <CountrySelector
                              countryCode={formFields.country.value}
                              updateCountryCode={(val) =>
                                this._onCountryUpdate(val)
                              }
                              onBlur={() => {}}
                            />
                            <div className="profileErrorText">
                              {formFields.country.isDirty &&
                              !formFields.country.isValid
                                ? "Not a valid country"
                                : null}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            State
                          </label>
                          <div className="col-sm-9">
                            <StateSelector
                              countryCode={formFields.state.value}
                              mode={
                                formFields.country.value === "United States"
                                  ? "dropdown"
                                  : "freetext"
                              }
                              updateCountryCode={(val) =>
                                this._updateFieldValue("state", val)
                              }
                              onBlur={() => {}}
                              inputClass="form-control"
                            />
                            <div className="profileErrorText">
                              {formFields.state.isDirty &&
                              !formFields.state.isValid
                                ? "Cannot be empty"
                                : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Phone
                          </label>
                          <div className="col-sm-9">
                            {/* <input
                              type="text"
                              className="form-control"
                              placeholder="Phone"
                              value={formFields.phone.value}
                              onChange={(e) =>
                                this._updatePhoneFieldValue(e.target.value)
                              }
                              onBlur={() => this._markAsDirty("phone")}
                            /> */}
                            <PhoneNumberInput
                              countryCode={countryCode}
                              updateCountryCode={this._onCountryCodeUpdate}
                              phone={formFields.phone.value}
                              updatePhoneNumber={(phone) =>
                                this._updateFieldValue("phone", phone)
                              }
                              onBlur={() => {}}
                            />
                            <div className="profileErrorText">
                              {formFields.phone.isDirty &&
                              !formFields.phone.isValid
                                ? "Please provide a valid phone number"
                                : null}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">
                            Company Name
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Company Name"
                              value={formFields.companyName.value}
                              onChange={(e) =>
                                this._updateFieldValue(
                                  "companyName",
                                  e.target.value
                                )
                              }
                              onBlur={() => this._markAsDirty("companyName")}
                            />
                            <div className="profileErrorText">
                              {formFields.companyName.isDirty &&
                              !formFields.companyName.isValid
                                ? "Cannot be empty"
                                : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group row">
                          <label className="col-sm-3 col-form-label">Zip</label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Zip"
                              value={formFields.zip.value}
                              onChange={(e) =>
                                this._updateFieldValue("zip", e.target.value)
                              }
                              onBlur={() => this._markAsDirty("zip")}
                            />
                            <div className="profileErrorText">
                              {formFields.zip.isDirty && !formFields.zip.isValid
                                ? "Cannot be empty"
                                : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <button class="btn btn-primary" type="submit">
                          Update
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (text) => dispatch(showLoader(text)),
    hideLoader: () => dispatch(hideLoader()),
    updateUserData: (userData) => dispatch(updateUserData(userData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);
