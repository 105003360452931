import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import ProtectedRoute from "../components/protected-routes";
import PublicRoute from "../components/public-route";
import AdminDashboardLayout from "./components/admin-dashboard-layout/admin-dashboard-layout";
import AdminForgotPasswordPage from "./pages/admin-forgot-password-page/admin-forgot-password-page";
import AdminLoginPage from "./pages/admin-login-page/admin-login-page";

class AdminModule extends Component {
  render() {
    return (
      <>
        <Switch>
          <PublicRoute
            path="/admin/forgot-password"
            component={AdminForgotPasswordPage}
            redirectRoute="/admin/private"
          />
          <ProtectedRoute
            path="/admin/private"
            component={AdminDashboardLayout}
            redirectTo="/admin/login"
          />
          <PublicRoute
            path="/admin"
            component={AdminLoginPage}
            redirectRoute="/admin/private"
          />
          <Route path="*" render={() => <Redirect to="/admin/private" />} />
        </Switch>
      </>
    );
  }
}

export default AdminModule;
