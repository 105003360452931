import React, { Component } from "react";
import Modal from "react-awesome-modal";
import "./event-register-prompt.scss";
import moment from "moment";
import { connect } from "react-redux";
import { hideLoader, showLoader } from "../../redux/actions/loader-data";
import { eventRegistration } from "../../http-calls";
import { convert24To12, showToast } from "../../helper-methods";

class EventRegisterPrompt extends Component {
  state = {
    selectedFocus: null,
    selectedSlot: null,
  };

  _canSubmit = () => {
    return this.state.selectedFocus && this.state.selectedSlot;
  };

  _toggleSlotSelection = (slot) => {
    let { selectedSlot } = this.state;
    if (selectedSlot && selectedSlot.slotId === slot.slotId) {
      // Deselect
      selectedSlot = null;
    } else {
      if (!slot.alreadyRegistered) {
        selectedSlot = slot;
      }
    }
    this.setState({ selectedSlot });
  };

  _getSlotClass = (slot) => {
    const { selectedSlot } = this.state;
    if (selectedSlot && selectedSlot.slotId === slot.slotId) {
      return "selector selected";
    } else {
      if (slot.alreadyRegistered) {
        return "selector disabled";
      } else {
        return "selector";
      }
    }
  };

  _toggleFocusSelection = (focus) => {
    let { selectedFocus } = this.state;
    if (selectedFocus && selectedFocus.focus_id === focus.focus_id) {
      // Deselect
      selectedFocus = null;
    } else {
      selectedFocus = focus;
    }
    this.setState({ selectedFocus });
  };

  _getFocusClass = (focus) => {
    const { selectedFocus } = this.state;
    if (selectedFocus && selectedFocus.focus_id === focus.focus_id) {
      return "selector selected";
    } else {
      return "selector";
    }
  };

  _submit = async () => {
    const { event, onDimiss, onSuccess } = this.props;
    const { selectedSlot, selectedFocus } = this.state;

    if (this._canSubmit()) {
      this.props.showLoader();
      const payload = {
        eventId: event.eventId,
        eventSlotId: selectedSlot.slotId,
        focusId: selectedFocus.focus_id,
      };
      try {
        await eventRegistration(payload);
        onSuccess();
      } catch (error) {
        this.props.hideLoader();
        onDimiss();
        showToast("Registration Failed");
      }
    }
  };

  _dismiss = () => {
    this._resetState();
    this.props.onDimiss();
  };

  _resetState = () => {
    this.setState({
      selectedFocus: null,
      selectedSlot: null,
    });
  };

  render() {
    const { isVisible, event, onDimiss, onSuccess, allFocus } = this.props;

    return (
      <>
        <Modal
          visible={isVisible}
          width="800"
          height="450"
          effect="fadeInUp"
          onClickAway={this._dismiss}
        >
          {event ? (
            <div className="registerPromptWRapper">
              <div className="eventTitle">
                <div className="eventName">{event.eventName}</div>
                <div className="date">
                  {moment(event.startDate, "DD/MM/YYYY").format("DD MMM YYYY")}{" "}
                  - {moment(event.endDate, "DD/MM/YYYY").format("DD MMM YYYY")}
                </div>
              </div>
              <div className="eventDetailsSelector">
                <div className="selectorWrapper">
                  <div className="label">Select a foucs</div>
                  <div className="selectors">
                    {allFocus.map((focus) => (
                      <div
                        className={this._getFocusClass(focus)}
                        key={focus.focus_id}
                        onClick={(e) => this._toggleFocusSelection(focus)}
                      >
                        {focus.focus_name}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="selectorWrapper">
                  <div className="label">Select a time slot</div>
                  <div className="selectors">
                    {event.slots.map((slot) => (
                      <div
                        key={slot.slotId}
                        className={this._getSlotClass(slot)}
                        onClick={(e) => this._toggleSlotSelection(slot)}
                      >
                        {convert24To12(slot.startTime)} -{" "}
                        {convert24To12(slot.endTime)}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="eventActions">
                <div className="cancel" onClick={this._dismiss}>
                  Dismiss
                </div>
                <div
                  className={
                    this._canSubmit() ? "register" : "register disabled"
                  }
                  onClick={this._submit}
                >
                  Register
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </Modal>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (text) => dispatch(showLoader(text)),
    hideLoader: () => dispatch(hideLoader()),
  };
};

export default connect(null, mapDispatchToProps)(EventRegisterPrompt);
