/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { extractQueryParams } from "../../helper-methods";
import "./download-cert-page.scss";

const DownloadCertPage = () => {
  const location = useLocation();
  const history = useHistory();

  const _downloadFile = async (location) => {
    // console.log("location :>> ", location);
    const params = extractQueryParams(location.search);
    // console.log("params :>> ", params);
    const a = document.createElement("a");
    a.href = params.f;
    a.download = params.f.split("/").pop();
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const _navigateToPortal = () => {
    history.replace("/");
  };
  useEffect(() => {
    _downloadFile(location);
  }, [location]);
  return (
    <>
      <div className="downloadPageWrapper">
        <div className="downloadWrapper">
          <img
            src="https://www.iconpacks.net/icons/1/free-certificate-icon-867-thumb.png"
            alt=""
          />
          <p>
            Your file should be downloaded automatically
            <br />
            <a className="loginWrapper" onClick={_navigateToPortal}>
              Click here to navigate to the portal
            </a>
          </p>
        </div>
        <div className="footer">
          <img
            className="concordeLogo"
            src={require("../../assets/images/concorde-logo.png")}
            alt="logo"
          />
        </div>
      </div>
    </>
  );
};

export default DownloadCertPage;
