import {
  addNewSponsor,
  deleteSponsor,
  editSponsor,
  getAllUpcomingEvents,
} from "../../http-calls";

const ManageSponsorHelper = {
  getAllEvents: async () => {
    return await getAllUpcomingEvents();
  },
  addNewSponsor: async (sponsor) => {
    await addNewSponsor(sponsor);
  },
  deleteSponsor: async (sponsor) => {
    await deleteSponsor(sponsor);
  },
  editSponsor: async (sponsor) => {
    await editSponsor(sponsor);
  },
  categorisedEvents: (allEvents) => {
    const yearWiseCategories = {};
    allEvents.forEach((event) => {
      const startDateParts = event.start_date.split("/");
      const eventYear = startDateParts[2];
      if (!yearWiseCategories[eventYear]) {
        yearWiseCategories[eventYear] = {
          events: [],
          year: eventYear
        }
        // yearWiseCategories[eventYear].events = [];
        // yearWiseCategories[eventYear].year = eventYear;
      }
      const eventIndex = yearWiseCategories[eventYear].events.findIndex(eachEvent => eachEvent.event_id === event.event_id); 
      if (eventIndex === -1) {
        yearWiseCategories[eventYear].events.push(event);
      }
    });
    return Object.values(yearWiseCategories).reverse();
  },
};

export default ManageSponsorHelper;
