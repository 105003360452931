import { useState } from "react";

export const useFormFields = (initialState) => {
  const [fields, setValues] = useState(initialState);

  const handleChange = (event) => {
    setValues({
      ...fields,
      [event.target.name]: event.target.value,
    });
  };

  const updateField = (fieldName, value) => {
    setValues({
      ...fields,
      [fieldName]: value,
    });
  };

  const updateFields = (updatedFields) => {
    setValues({
      ...fields,
      ...updatedFields,
    });
  };

  const clearFields = () => {
    setValues(initialState);
  };

  return { fields, handleChange, updateField, clearFields, updateFields };
};
