import React, { Component } from "react";
import { EventEmitter } from "../../utils/event-emitter";

const EventSponsorsViewer = ({ event }) => {
  const { sponsors } = event;

  const _filterSponsors = () => {
    let filteredSponsors = sponsors;
    if (event.featuredSponsorId?.length) {
      filteredSponsors = filteredSponsors.filter(
        (sponsor) => sponsor.id !== event.featuredSponsorId
      );
    }
    return filteredSponsors;
  };

  const _showSponsorDetails = (sponsor) => {
    EventEmitter.dispatch("show-sponsor-details", sponsor);
  };

  const filteredSponsors = _filterSponsors();

  if (filteredSponsors?.length) {
    return (
      <>
        <div className="sponsorsLabels">Sponsors</div>
        <div className="sponsorsRow">
          {filteredSponsors?.map((sponsor) => (
            <div
              className="sponsorWrapper"
              key={sponsor.id}
              onClick={() => _showSponsorDetails(sponsor)}
            >
              {sponsor?.logo?.length ? (
                <img src={sponsor?.logo} alt="" />
              ) : (
                <>{sponsor.name}</>
              )}
            </div>
          ))}
        </div>
      </>
    );
  } else {
    return <></>;
  }
};

export default EventSponsorsViewer;
